.edit-hotspots {
  height: 100%; }

.content-config {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out; }
  .content-config.collapsed {
    overflow: hidden;
    max-height: 0; }
  .content-config .content {
    padding: 15px 0; }

.hotspots-navigate-bar {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #b0b0b0;
  -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.5); }
  .hotspots-navigate-bar.edit-mode-off {
    background-color: #f97416;
    cursor: pointer;
    color: #e3e3e3; }
    .hotspots-navigate-bar.edit-mode-off .indicators {
      display: none; }
  .hotspots-navigate-bar.edit-mode-on {
    background-color: #1e1e1e; }
  .hotspots-navigate-bar a.where {
    color: #cacaca; }
  .hotspots-navigate-bar a.switch-to-navigate {
    color: #b0b0b0;
    font-size: 18px;
    float: right;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    .hotspots-navigate-bar a.switch-to-navigate:hover {
      color: #e3e3e3; }
  .hotspots-navigate-bar .indicators {
    display: inline-block;
    margin-left: 25px; }
    .hotspots-navigate-bar .indicators .hotspot-indicator {
      display: inline-block;
      height: 1em;
      margin: 0 3px;
      position: relative;
      top: 2px;
      width: 1em;
      -webkit-border-radius: 1em;
      -moz-border-radius: 1em;
      -ms-border-radius: 1em;
      -o-border-radius: 1em;
      border-radius: 1em;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out; }
      .hotspots-navigate-bar .indicators .hotspot-indicator.empty {
        border: 1px dotted #e3e3e3; }
      .hotspots-navigate-bar .indicators .hotspot-indicator.active {
        background-color: #ff765c; }
      .hotspots-navigate-bar .indicators .hotspot-indicator.error {
        background-color: #fecc00; }
      .hotspots-navigate-bar .indicators .hotspot-indicator .details {
        background-color: white;
        border: 1px solid #b4bcc2;
        display: none;
        font-size: 12px;
        line-height: 20px;
        padding: 5px;
        text-align: center;
        width: 150px;
        position: absolute;
        bottom: 100%;
        margin-bottom: 10px;
        left: 50%;
        margin-left: -75px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        .hotspots-navigate-bar .indicators .hotspot-indicator .details:before {
          left: 50%;
          margin-left: -10px;
          top: 100%;
          position: absolute;
          width: 0;
          height: 0;
          content: "";
          z-index: 2;
          border-top: 10px solid #b4bcc2;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent; }
      .hotspots-navigate-bar .indicators .hotspot-indicator:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1; }
        .hotspots-navigate-bar .indicators .hotspot-indicator:hover div.details {
          display: block; }
