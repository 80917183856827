.hotspot-summary {
  color: #4a5353;
  font-size: 16px;
  line-height: 1.5em;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out; }
  .opened.hotspot-summary {
    color: rgba(74, 83, 83, 0.5); }

.hotspot-summary .hotspots-setting {
  color: #4a5353;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  position: relative; }
  .hotspot-summary .open-panel.hotspots-setting {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: pointer; }
  .hotspot-summary .with-error.hotspots-setting {
    position: relative; }
    .hotspot-summary .with-error.hotspots-setting:after {
      top: 100%;
      margin-top: -5px;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(179, 78, 77, 0);
      border-bottom-color: #b34e4d;
      border-width: 10px;
      margin-left: -10px; }

.hotspot-summary.opened .hotspots-setting {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  color: rgba(74, 83, 83, 0.5);
  -webkit-transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out; }
  .hotspot-summary.opened .hotspots-setting:hover, .hotspot-summary.opened .active.hotspots-setting {
    border-bottom-color: rgba(0, 0, 0, 0.7);
    color: #4a5353; }

.dismissable-choice, .frequency-choice {
  padding: 20px;
  text-align: left; }
  .dismissable-choice .choice-label, .frequency-choice .choice-label {
    font-size: 16px;
    height: 34px;
    line-height: 34px; }
  .dismissable-choice .icon, .frequency-choice .icon {
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    height: 35px;
    margin-right: 15px;
    position: relative;
    width: 55px; }
    .dismissable-choice .icon .fill, .frequency-choice .icon .fill {
      background-color: white;
      position: absolute; }
