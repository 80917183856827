.stat-panel {
  border-top-width: 3px;
  border-top-style: solid;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px; }
  .stat-panel li:first-child, .stat-panel .panel {
    border-top: 0;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0; }
  .stat-panel.stat-panel-default {
    border-top-color: #848484; }
  .stat-panel.stat-panel-primary {
    border-top-color: #5c5cff; }
  .stat-panel.stat-panel-success {
    border-top-color: #06db82; }
  .stat-panel.stat-panel-info {
    border-top-color: #00b2e5; }
  .stat-panel.stat-panel-warning {
    border-top-color: #fecc00; }
  .stat-panel.stat-panel-danger {
    border-top-color: #f97416; }
  .stat-panel p {
    margin-bottom: 0; }
