.offset-controls i {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .offset-controls i:hover {
    color: #1e1e1e; }

body,
input {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

.offset-controls i {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .offset-controls i:hover {
    color: #1e1e1e; }

body,
input {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

.hover-controls {
  background-color: rgba(30, 30, 30, 0.9);
  display: none;
  flex-direction: row;
  font-size: small;
  font-weight: 400;
  padding: 3px 6px;
  position: absolute;
  text-align: center;
  bottom: 100%;
  left: -1px;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0; }
  .hover-controls a {
    color: #bbbbbb;
    cursor: pointer;
    display: flex;
    padding: 4px 6px;
    margin-right: 5px;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    white-space: nowrap; }
    .hover-controls a:last-child {
      margin-right: 0; }
    .hover-controls a:hover {
      color: white;
      text-decoration: none; }
    .hover-controls a.component-drag-handle {
      cursor: move; }
    .hover-controls a i {
      line-height: 23px; }

* {
  -webkit-font-smoothing: antialiased; }

html,
body {
  height: 100%;
  width: 100%; }

a, .text-link {
  cursor: pointer;
  color: #5c5cff; }

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong {
  font-weight: 600; }

h1 {
  font-weight: 400;
  color: #364760; }

h2 {
  color: #364760;
  font-size: 21px;
  font-weight: 700;
  padding: 10px 0 20px;
  line-height: normal;
  margin: 0;
  -webkit-margin-before: 0.83em;
  -webkit-margin-after: 0.83em; }

.flexbox {
  display: flex; }

.pull-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.affix-top {
  position: static; }

.affix-bottom {
  position: absolute; }

.remove-category {
  color: #a94442; }
  .remove-category:hover {
    color: #843534; }

.bottom-space {
  margin-bottom: 10px; }

.hint:after, [data-hint]:after {
  box-shadow: none;
  text-shadow: none;
  border-radius: 4px; }

.dashed {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 3px dashed #e3e3e3; }
  .dashed.dashed-sm {
    border-width: 1px; }
  .dashed.dashed-lg {
    border-width: 6px; }

.inline-list {
  white-space: nowrap; }

.well {
  background-image: none; }

a.well, a.panel-body {
  display: block; }
  a.well:focus, a.well:hover, a.panel-body:focus, a.panel-body:hover {
    text-decoration: none; }

.btn {
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-weight: 400; }
  .btn.btn-link {
    color: #848484;
    font-weight: 400; }
    .btn.btn-link:hover {
      color: #4c4c4c;
      text-decoration: none; }
  .btn .fa {
    margin-right: 0.5em; }
  .btn:active {
    transform: scale(0.96, 0.94);
    transition-duration: 0;
    opacity: 0.94; }

.btn-addon {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #cccccc; }

.btn-muted {
  color: #4c4c4c;
  background-color: #e3e3e3; }
  .btn-muted:hover {
    color: #4c4c4c;
    background-color: #d6d6d6; }

.btn-outline {
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 0 0 1px #e3e3e3;
  color: #848484;
  background-color: transparent;
  -webkit-transition: all, 0.15s, ease;
  -moz-transition: all, 0.15s, ease;
  -o-transition: all, 0.15s, ease;
  transition: all, 0.15s, ease; }
  .btn-outline:hover {
    border-color: #e3e3e3;
    color: white; }
  .btn-outline:hover {
    color: #4c4c4c;
    border-color: #bbbbbb; }
  .btn-outline.btn-outline-primary {
    border: 1px solid #5c5cff;
    box-shadow: inset 0 0 0 1px #5c5cff;
    color: #5c5cff;
    background-color: transparent; }
    .btn-outline.btn-outline-primary:hover {
      border-color: #5c5cff;
      color: white; }
  .btn-outline.btn-outline-success {
    border: 1px solid #06db82;
    box-shadow: inset 0 0 0 1px #06db82;
    color: #06db82;
    background-color: transparent; }
    .btn-outline.btn-outline-success:hover {
      border-color: #06db82;
      color: white; }
  .btn-outline.btn-outline-danger {
    border: 1px solid #f97416;
    box-shadow: inset 0 0 0 1px #f97416;
    color: #f97416;
    background-color: transparent; }
    .btn-outline.btn-outline-danger:hover {
      border-color: #f97416;
      color: white; }
  .btn-outline.btn-outline-warning {
    border: 1px solid #fecc00;
    box-shadow: inset 0 0 0 1px #fecc00;
    color: #fecc00;
    background-color: transparent; }
    .btn-outline.btn-outline-warning:hover {
      border-color: #fecc00;
      color: white; }

.dropdown-menu .dropdown-header {
  padding: 1rem 1rem 0.15rem;
  font-family: "Muli", "Avenir", "Futura", "Avant Garde", "Century Gothic", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  color: #5c5cff;
  letter-spacing: 0.07em; }
  .dropdown-menu .dropdown-header:first-child {
    padding-top: 0.5rem; }
.dropdown-menu .divider {
  height: 2px;
  background-color: #f4f4f4;
  margin: 8px 0 4px; }
.dropdown-menu > li > a {
  padding: 0.25rem 1rem; }
.dropdown-menu > li:not(.dropdown-header) + li.dropdown-header {
  padding-top: 1rem; }

.fa-accent.fa-twitter, .fa-accent.fa-twitter-square {
  color: #46c0fb; }
.fa-accent.fa-facebook, .fa-accent.fa-facebook-square {
  color: #4863ae; }
.fa-accent.fa-linkedin, .fa-accent.fa-linkedin-square {
  color: #0083a8; }

.with-offset-controls {
  margin-right: 45px; }

.offset-controls {
  line-height: 30px;
  top: 1rem;
  right: -3rem;
  position: absolute;
  width: 36px;
  margin-bottom: 0; }
  .offset-controls li {
    margin-bottom: 1rem;
    max-height: 28px; }
    .offset-controls li.divider {
      border-top: 1px solid #cccccc; }

hr[data-content] {
  margin: 30px 0;
  text-align: center; }
  hr[data-content]:after {
    content: attr(data-content);
    position: relative;
    top: -1rem;
    background-color: white;
    border-radius: 0;
    border: 1px solid #eeeeee;
    padding: 1px 9px 1px;
    text-align: center;
    display: inline-block;
    font-size: 85%; }

.checkbox-toggle {
  display: none; }

input.checkbox-toggle:checked + .checkbox-toggle {
  display: block; }

.input-group .input-group-floater {
  position: absolute;
  top: 6px;
  right: 6px; }

.btn-toggle .fa-check {
  color: #e3e3e3; }
.btn-toggle.toggle-on .fa-check {
  color: #06db82; }

.fa-copy.fa-fw.fa-lg {
  font-size: 1.2em; }

.list-group-item.draggable-list-group-item {
  position: relative; }
  .list-group-item.draggable-list-group-item.sortable-ghost {
    background-color: #f8f8f8; }
    .list-group-item.draggable-list-group-item.sortable-ghost .drag-handle {
      background-color: #f8f8f8; }
  .list-group-item.draggable-list-group-item .drag-handle {
    width: 9px;
    height: 50%;
    cursor: move;
    background-color: white;
    background-image: linear-gradient(90deg, #cccccc 50%, rgba(255, 255, 255, 0.5) 50%);
    background-size: 3px 3px;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 25%; }

ol.checklist li.done {
  text-decoration: line-through;
  color: #cccccc; }

table.table-layout {
  width: 100%; }
  table.table-layout td {
    vertical-align: top; }

table.table-padded {
  width: 100%; }
  table.table-padded td {
    padding: 1rem; }

.display-table {
  display: table;
  width: 100%; }
  .display-table .table-cell {
    display: table-cell; }
  .display-table .cell-space {
    padding: 0 10px; }

.radio-reveal .radio-content {
  padding-left: 20px; }

.flex-row {
  display: flex;
  margin-right: -2rem;
  flex-wrap: wrap; }

.flex-col {
  flex: 1;
  margin-right: 2rem; }

.status-dot {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px; }
  .status-dot.status-critical {
    background-color: #f97416; }
  .status-dot.status-major {
    background-color: #f97416; }
  .status-dot.status-minor {
    background-color: #fecc00; }
  .status-dot.status-none {
    background-color: #06db82; }

.offset-controls i {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .offset-controls i:hover {
    color: #1e1e1e; }

body,
input {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

#app-wrapper {
  width: 100%;
  min-height: 100%;
  height: auto !important;
  margin: 0;
  background-color: white; }

#app {
  width: 100%;
  min-height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 80px; }

body {
  padding-right: 20px; }
  body:not(.isIFramed) {
    margin: 0;
    font-size: 16px;
    padding: 1rem; }
  body.isIFramed {
    padding-right: 0;
    line-height: normal; }
    body.isIFramed h1, body.isIFramed h2, body.isIFramed h3, body.isIFramed h4, body.isIFramed h5, body.isIFramed h6, body.isIFramed .h1, body.isIFramed .h2, body.isIFramed .h3, body.isIFramed .h4, body.isIFramed .h5, body.isIFramed .h6 {
      line-height: normal; }
    body.isIFramed h1 {
      font-size: 32px;
      font-weight: 500;
      color: #081731; }
    body.isIFramed .modal-backdrop.in {
      opacity: 0; }
    body.isIFramed .modal-dialog .modal-content {
      box-shadow: 0 4px 88px rgba(0, 0, 0, 0.4), 0 2px 2px 0 rgba(0, 0, 0, 0.2); }
    body.isIFramed .auth-banner {
      display: none; }
    body.isIFramed .header-container {
      margin-top: 0.5em; }
      body.isIFramed .header-container h1, body.isIFramed .header-container h2 {
        margin-top: 0; }
    body.isIFramed .container {
      padding-left: 0;
      padding-right: 0; }
    @media (min-width: 992px) {
      body.isIFramed .container {
        width: auto; } }
    @media (min-width: 768px) {
      body.isIFramed .container {
        width: auto; } }

.secondary-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  height: 54px;
  clear: both; }
  .secondary-row > .container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .secondary-row > .container > .row {
      flex-grow: 1; }

.page-actions {
  margin-top: 0.75rem; }
  .page-actions .create-announcement {
    margin-right: 15px; }

.main-row {
  background-color: white;
  clear: both; }
  .main-row .toggle-flow-view {
    font-size: 13px;
    color: #4c4c4c;
    cursor: pointer; }
    .main-row .toggle-flow-view:hover {
      color: #277ca0; }

#apc-react-root {
  text-align: center;
  margin-top: -12px;
  position: relative; }
  #apc-react-root .apc-react-body {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px; }
  @media (min-width: 768px) {
    #apc-react-root .apc-react-body {
      width: 750px; } }
  @media (min-width: 992px) {
    #apc-react-root .apc-react-body {
      width: 970px; } }
  @media (min-width: 1200px) {
    #apc-react-root .apc-react-body {
      width: 1170px; } }
  #apc-react-root page-container {
    display: block;
    padding-top: 8px; }

.sidebar .affixed-content {
  top: 20px; }
  .sidebar .affixed-content.affix-top {
    position: static; }
  .sidebar .affixed-content > div {
    margin-bottom: 20px; }

@media (min-width: 992px) {
  .sidebar .affixed-content.affix, .sidebar .affixed-content.affix-bottom {
    width: 212px; } }
@media (min-width: 1200px) {
  .sidebar .affixed-content.affix, .sidebar .affixed-content.affix-bottom {
    width: 262.5px; } }
@media (max-width: 991px) {
  .sidebar .affixed-content.affix, .sidebar .affixed-content.affix-top, .sidebar .affixed-content.affix-bottom {
    position: static; } }
.sidebar-content {
  background-color: white;
  padding: 15px; }

.banner {
  padding: 10px 0; }

.btn-ghost {
  color: #848484;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-duration: 100ms;
  -moz-transition-duration: 100ms;
  -o-transition-duration: 100ms;
  transition-duration: 100ms; }

.btn, a.label {
  transition: all 0.07s ease-out;
  font-weight: 600; }

.btn.copy {
  margin-left: 15px; }

.btn.disabled, .btn-success.disabled {
  opacity: 0.4; }

.btn.btn-sm {
  padding-top: 0.2em;
  padding-bottom: 0.2em; }

.input-group-btn .btn.btn-sm {
  padding-top: 0.4em;
  padding-bottom: 0.3em; }

.text-muted {
  font-weight: 200; }

.text-caption {
  margin-top: 0.25em;
  color: #bbbbbb;
  font-size: 13px;
  font-style: italic; }

label {
  color: #4c4c4c; }

.form-group {
  margin-bottom: 18px; }

.label {
  text-align: center;
  text-transform: uppercase;
  padding: 0.38em 1em 0.32em;
  font-weight: 600;
  font-smoothing: auto;
  -webkit-font-smoothing: auto;
  -mox-osx-font-smoothing: auto;
  letter-spacing: 0.08em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); }
  .label .fa {
    position: relative;
    top: -1px;
    font-size: 93%; }

pre {
  border: none; }

.nav-tabs > li a {
  border-width: 2px !important; }
.nav-tabs > li.active a {
  color: #4c4c4c; }
  .nav-tabs > li.active a:hover {
    color: #4c4c4c; }

.dropdown.open .btn-ghost {
  border: 1px solid #e3e3e3 !important;
  color: #848484;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px; }
.dropdown.open .dropdown-menu {
  -webkit-animation: slideDownSubtle 0.15s ease-out;
  animation: slideDownSubtle 0.15s ease-out; }

.dropdown-menu {
  -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
  border: none; }

.background-iframe iframe {
  position: fixed;
  top: 0;
  border: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url(/images/backgrounds/cubes.png) repeat center top;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }
  .background-iframe iframe.obscured {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    filter: blur(2px); }

.full {
  width: 100%; }

.half {
  width: 50%; }

.row-flex {
  display: flex; }

.flex-wrap {
  flex-wrap: wrap; }

.row-flex-centered {
  align-items: center; }

.row-flex-justify {
  justify-content: space-between; }

.row-flex-right {
  justify-content: flex-end; }

.item-flex {
  flex: 1; }

.item-flex-double {
  flex: 2; }

.no-padding {
  padding: 0; }

.system-status .alert-warning, .system-status .alert-info {
  text-align: center;
  padding: 20px 0; }

.page-heading {
  display: flex;
  padding: 10px 0 20px; }
  .page-heading .page-heading-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .page-heading .page-heading-title h2 {
      padding: 0; }
  .page-heading .page-heading-actions {
    flex-shrink: 0;
    display: flex;
    align-items: center; }
