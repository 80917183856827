.list-info {
  padding: 10px 0; }
  .list-info header, .list-info .buttons {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap; }
  .list-info header {
    padding: 0;
    width: 100%; }
    .list-info header input {
      width: 100%; }
    .list-info header + .buttons {
      padding-left: 10px; }
  .list-info .publish-btn-wrapper {
    display: inline-block;
    position: relative; }
    .list-info .publish-btn-wrapper .publish-btn.with-warnings {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=65);
      opacity: 0.65; }
    .list-info .publish-btn-wrapper .error-count {
      background-color: #ec7364;
      border-radius: 16px;
      color: white;
      font-size: 11px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      width: 16px;
      position: absolute;
      top: -4px;
      right: -4px;
      -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      -webkit-animation-duration: 0.25s;
      animation-duration: 0.25s;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out; }
    .list-info .publish-btn-wrapper .error-checklist-wrapper {
      display: none;
      padding-top: 10px;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 1100; }
    .list-info .publish-btn-wrapper:hover .error-checklist-wrapper {
      display: block; }
    .list-info .publish-btn-wrapper .error-checklist {
      background-color: white;
      border: 1px solid gainsboro;
      border-radius: 5px;
      position: relative;
      width: 320px;
      -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); }
      .list-info .publish-btn-wrapper .error-checklist:after {
        bottom: 100%;
        right: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(149, 213, 164, 0);
        border-bottom-color: gainsboro;
        border-width: 10px; }
      .list-info .publish-btn-wrapper .error-checklist ol {
        margin: 0; }
        .list-info .publish-btn-wrapper .error-checklist ol li {
          border-top: 1px solid #eeeeee;
          padding: 10px; }
          .list-info .publish-btn-wrapper .error-checklist ol li h5 {
            font-weight: bold;
            margin: 0 0 5px; }
          .list-info .publish-btn-wrapper .error-checklist ol li p {
            font-size: small;
            margin: 0;
            white-space: normal; }

.content-config {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out; }
  .content-config.collapsed {
    overflow: hidden;
    max-height: 0; }
  .content-config .content {
    padding: 15px 0; }

.list-info {
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .list-info .list-filters .filters-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none; }
    .list-info .list-filters .filters-nav > li {
      white-space: nowrap;
      line-height: inherit;
      padding-right: 1rem; }
  .list-info .list-filters li.dropdown a.dropdown-toggle {
    color: #848484;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: inherit;
    margin-left: 10px;
    padding: 0; }
    .list-info .list-filters li.dropdown a.dropdown-toggle span.filtered {
      font-weight: bold;
      color: #4c4c4c; }
    .list-info .list-filters li.dropdown a.dropdown-toggle:hover, .list-info .list-filters li.dropdown a.dropdown-toggle:focus {
      color: #1e1e1e;
      text-decoration: none; }
  .list-info .list-filters .search {
    display: inline-block;
    margin-left: 10px;
    padding: 0;
    position: relative;
    top: 2px; }
    .list-info .list-filters .search i {
      color: #848484; }
    .list-info .list-filters .search label:hover + input {
      border-bottom: 1px dashed #e3e3e3; }
    .list-info .list-filters .search [name=nameFilter] {
      background: transparent;
      border: 0;
      border-bottom: 1px dashed transparent;
      color: #4c4c4c;
      outline: 0;
      margin-left: 5px;
      padding: 0;
      width: 200px;
      -webkit-transition: 0.25s border-bottom-color ease-out;
      -moz-transition: 0.25s border-bottom-color ease-out;
      -o-transition: 0.25s border-bottom-color ease-out;
      transition: 0.25s border-bottom-color ease-out; }
      .list-info .list-filters .search [name=nameFilter]:focus, .list-info .list-filters .search [name=nameFilter]:hover {
        border-bottom: 1px dashed #bbbbbb;
        color: #1e1e1e; }
      .list-info .list-filters .search [name=nameFilter].name-filter-active {
        border-bottom-color: #5c5cff;
        color: #5c5cff; }
  .list-info .fa-cog {
    margin-right: 8px; }
