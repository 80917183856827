.card-icon {
  height: 1.4em;
  vertical-align: top; }

.panel.panel-plan {
  min-width: 210px;
  position: relative; }
  .panel.panel-plan .panel-heading {
    background-color: #4c4c4c;
    color: white;
    text-transform: uppercase;
    border-width: 2px;
    margin: -2px -2px 0 -2px; }
    .panel.panel-plan .panel-heading h2 {
      letter-spacing: 0.08em;
      font-weight: 300; }
  .panel.panel-plan .panel-callout {
    background-color: #e3e3e3;
    margin: 0 -2px 0 -2px;
    min-height: 100px; }
    .panel.panel-plan .panel-callout h4 {
      font-size: 3em;
      font-weight: 600;
      text-shadow: 0 0 0.5em rgba(255, 255, 255, 0.8); }
      .panel.panel-plan .panel-callout h4 sup {
        font-size: 60%; }
      .panel.panel-plan .panel-callout h4 small {
        color: inherit;
        font-size: 50%; }
    .panel.panel-plan .panel-callout.panel-accent {
      background-color: #06db82;
      margin-top: -2px;
      color: white; }
      .panel.panel-plan .panel-callout.panel-accent h4 {
        text-shadow: 0 0 0.5em #09bb71; }
  .panel.panel-plan .btn-block {
    position: relative;
    width: 100%;
    bottom: 0; }
  .panel.panel-plan .table td {
    font-weight: 400;
    font-size: 16px; }
  .panel.panel-plan .table tr:first-child td {
    border-top: none;
    padding-top: 0; }
  .panel.panel-plan.animated[data-animation-delay='0'] {
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }
  .panel.panel-plan.animated[data-animation-delay='1'] {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
  .panel.panel-plan.animated[data-animation-delay='2'] {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  .panel.panel-plan.animated[data-animation-delay='3'] {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }
  .panel.panel-plan.animated[data-animation-delay='4'] {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s; }
  .panel.panel-plan.animated[data-animation-delay='5'] {
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
