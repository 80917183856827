select, select.form-control, .selectize-control.single .selectize-input {
  background-image: url(/images/dropdown-caret-down.png);
  background-size: 0.7rem;
  background-position: right 10px center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  select::-ms-expand, select.form-control::-ms-expand, .selectize-control.single .selectize-input::-ms-expand {
    display: none; }
  select:active, select.dropdown-active, select.input-active, select.form-control:active, select.form-control.dropdown-active, select.form-control.input-active, .selectize-control.single .selectize-input:active, .selectize-control.single .selectize-input.dropdown-active, .selectize-control.single .selectize-input.input-active {
    background: url(/images/dropdown-caret-up.png) !important;
    background-size: 0.7rem !important;
    background-position: right 10px center !important;
    background-repeat: no-repeat !important; }

.selectize-input.focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

.input-colorpicker-group .input-group-addon i {
  vertical-align: middle; }

.input-rangeslider {
  width: 100%; }
  .input-rangeslider .rangeslider {
    height: 10px;
    margin-top: 10px;
    background: #e3e3e3;
    box-shadow: none;
    border-radius: 3px; }
    .input-rangeslider .rangeslider .rangeslider__fill {
      background-color: #5c5cff;
      box-shadow: none;
      border-radius: 3px; }
  .input-rangeslider .rangeslider__handle {
    height: 20px;
    top: -5px;
    border-radius: 3px;
    box-shadow: 0 0 0 2px white;
    background: #f4f4f4;
    border: 2px solid #d9d9d9; }
    .input-rangeslider .rangeslider__handle:after {
      background: 0; }
