.setup-page {
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 60px; }
  .setup-page > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .setup-page > .container .template-welcome {
      display: flex;
      flex-direction: column;
      max-width: 620px;
      align-items: center; }
      .setup-page > .container .template-welcome h1 {
        margin-top: 0;
        margin-bottom: 20px; }
      .setup-page > .container .template-welcome p {
        margin-bottom: 32px; }
      .setup-page > .container .template-welcome .create-flow-btn {
        min-width: 210px; }
    .setup-page > .container .template-selection-container {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin: 0 0 32px; }
      .setup-page > .container .template-selection-container .select-template-button {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #e6e5e3;
        padding: 28px;
        cursor: pointer; }
        .setup-page > .container .template-selection-container .select-template-button:hover {
          border-color: #2ca6e5; }
          .setup-page > .container .template-selection-container .select-template-button:hover:active, .setup-page > .container .template-selection-container .select-template-button:hover:focus {
            border-color: #138dcb; }
        .setup-page > .container .template-selection-container .select-template-button + .select-template-button {
          margin-left: 30px; }
        .setup-page > .container .template-selection-container .select-template-button * {
          cursor: pointer; }
        .setup-page > .container .template-selection-container .select-template-button h2 {
          font-size: 18px;
          font-weight: 600;
          color: #4e4d4c;
          margin-bottom: 6px; }
        .setup-page > .container .template-selection-container .select-template-button .template-description {
          color: #7f7d7c;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          line-height: 19px; }
    .setup-page > .container .template-next-steps {
      width: 100%;
      margin: 64px 0 32px;
      color: #bbbbbb;
      text-transform: uppercase;
      font-weight: 900;
      text-align: center; }
