#build-published-banner {
  min-height: 29px; }
  #build-published-banner > div {
    font-size: 14px;
    padding: 5px;
    font-weight: 400; }
    #build-published-banner > div a {
      font-weight: 500; }
    #build-published-banner > div.affix {
      top: 0;
      right: 0;
      left: 0;
      z-index: 1000; }
    #build-published-banner > div.build-published-info-content {
      background-color: #def7ff;
      border-bottom: 2px solid #00a2d1;
      color: #006d8c; }
    #build-published-banner > div.build-published-warning-content {
      background-color: #fef6d9;
      border-bottom: 2px solid #eabc00;
      color: #8d7102; }

#build-navigation {
  padding: 10px 0; }
  #build-navigation ul {
    margin-bottom: 0;
    padding: 7px 0; }
  #build-navigation .link {
    color: #bbbbbb; }
    #build-navigation .link:hover {
      color: #848484;
      text-decoration: none; }
  #build-navigation .active-link {
    font-weight: bold;
    color: #1e1e1e; }
    #build-navigation .active-link:hover {
      text-decoration: none; }
  #build-navigation .arrow {
    color: #bbbbbb; }
  #build-navigation .publish-options a.btn:not(:last-child) {
    margin-right: 5px; }

#build-location-bar {
  background-color: #f7f7f7;
  padding: 10px 0; }
  #build-location-bar h5 {
    margin: 0 0 8px; }
  #build-location-bar .input-wrapper {
    background-color: white;
    border: 1px solid #bbbbbb;
    display: table;
    padding: 3px 3px 3px 10px;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px; }
    #build-location-bar .input-wrapper input {
      background: transparent;
      border: none;
      display: table-cell;
      font-size: 16px;
      outline: none;
      padding: 3px 0;
      width: 100%; }
    #build-location-bar .input-wrapper .indicator {
      display: table-cell;
      width: 24px; }
      #build-location-bar .input-wrapper .indicator a {
        display: block;
        position: relative;
        top: -1px;
        text-align: center; }
      #build-location-bar .input-wrapper .indicator .loading, #build-location-bar .input-wrapper .indicator .navigate {
        display: none; }
      #build-location-bar .input-wrapper .indicator.loading .loading, #build-location-bar .input-wrapper .indicator.navigate .navigate {
        display: inline; }

#build-preview-frame {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e; }
  #build-preview-frame iframe.build-frame, #build-preview-frame iframe.eager-preview-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white; }
  #build-preview-frame .steps {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }

.build-bar-setting {
  color: inherit;
  display: inline-block;
  font-weight: inherit;
  margin: 0 5px;
  padding: 10px;
  vertical-align: middle; }
  .build-bar-setting:hover {
    color: inherit;
    text-decoration: none; }
  .build-bar-setting.closed:after {
    color: #e3e3e3;
    content: "\f0d7";
    font-family: "FontAwesome";
    margin-left: 5px; }
  .build-bar-setting.open:after {
    color: #e3e3e3;
    content: "\f0d8";
    font-family: "FontAwesome";
    margin-left: 5px; }
  .build-bar-setting.disabled {
    color: #848484; }

.build-bar-dropdown .build-bar-dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999;
  background-color: #f7f7f7;
  min-width: 200px;
  -webkit-box-shadow: 1px 1px 1px rgba(132, 132, 132, 0.5);
  -moz-box-shadow: 1px 1px 1px rgba(132, 132, 132, 0.5);
  box-shadow: 1px 1px 1px rgba(132, 132, 132, 0.5);
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
  .build-bar-dropdown .build-bar-dropdown-content.grid {
    min-width: 180px; }
  .build-bar-dropdown .build-bar-dropdown-content div.window-grid {
    display: flex;
    width: 100%; }
    .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column {
      display: flex;
      flex-direction: column;
      width: 60px; }
      .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square {
        display: block;
        width: 50px;
        height: 50px;
        border: 1px solid #e3e3e3;
        background-color: white;
        margin: 5px; }
        .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square.disabled {
          background-color: #f7f7f7; }
        .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square:not(.disabled):hover {
          border: 1px solid #5c5cff; }
        .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square i {
          display: none;
          padding-top: 5px; }
        .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square input[type="radio"]:checked + i {
          display: block;
          font-family: "FontAwesome";
          filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
          opacity: 0.8; }
        .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square.selected {
          color: white;
          background-color: #5c5cff; }
          .build-bar-dropdown .build-bar-dropdown-content div.window-grid div.column label.square.selected.disabled {
            background-color: #bbbbbb; }
.build-bar-dropdown:hover {
  background-color: #f7f7f7; }
.build-bar-dropdown:hover .build-bar-dropdown-content {
  display: block; }
.build-bar-dropdown label {
  cursor: pointer;
  display: block;
  font-weight: inherit;
  padding: 10px 15px; }
  .build-bar-dropdown label:hover {
    background-color: #5c5cff;
    color: white; }
  .build-bar-dropdown label.disabled {
    color: #e3e3e3;
    cursor: not-allowed; }
  .build-bar-dropdown label .option-description {
    font-size: small;
    margin: 2px 0 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7; }
.build-bar-dropdown input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
.build-bar-dropdown input[type="radio"]:checked + .option-label::before {
  content: "\f00c";
  float: right;
  font-family: "FontAwesome";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }
.build-bar-dropdown .build-bar-dropdown-divider {
  height: 1px;
  width: auto;
  background-color: #dddddd;
  margin-bottom: 5px; }

#build-flow-steps, #build-hotspots {
  display: none; }
  #build-flow-steps.open, #build-hotspots.open {
    display: block; }
  #build-flow-steps .content-thumbnails, #build-hotspots .content-thumbnails {
    margin: 1rem 0; }

#build-preview-frame .skip {
  background-color: #dddddd;
  color: #888888;
  cursor: pointer;
  font-size: 22px;
  line-height: 22px;
  padding: 3px 8px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out; }
  #build-preview-frame .skip:hover {
    color: #222222;
    text-decoration: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }
#build-preview-frame .left-pattern .skip {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  top: 0; }

.build-content label input[type=radio] {
  margin-right: 10px; }
.build-content .radio:last-child, .build-content .checkbox:last-child {
  margin-bottom: 0; }
.build-content .panel-body .radio:first-child, .build-content .panel-body .checkbox:first-child {
  margin-top: 0; }
.build-content .panel-body .radio:last-child, .build-content .panel-body .checkbox:last-child {
  margin-bottom: 0; }
