.panel {
  border-width: 2px;
  border-color: #e3e3e3;
  box-shadow: none; }
  .panel .panel-title {
    font-weight: 400; }
    .panel .panel-title.caps-title {
      text-transform: uppercase;
      letter-spacing: 0.08em; }

.panel .panel-heading, .panel.panel-default .panel-heading {
  background-color: #f4f4f4;
  border-bottom: 2px solid #e3e3e3; }

.panel-muted {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-muted.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-muted.panel-hover .panel-body:before, .panel-muted.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-muted.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #e3e3e3;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-muted.panel-hover .panel-body a, .panel-muted.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-muted.panel-hover:hover {
    border-color: #e3e3e3; }
    .panel-muted.panel-hover:hover .panel-body {
      color: white; }
      .panel-muted.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-muted.panel-hover:hover .panel-body a, .panel-muted.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-primary {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-primary.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-primary.panel-hover .panel-body:before, .panel-primary.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-primary.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #5c5cff;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-primary.panel-hover .panel-body a, .panel-primary.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-primary.panel-hover:hover {
    border-color: #5c5cff; }
    .panel-primary.panel-hover:hover .panel-body {
      color: white; }
      .panel-primary.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-primary.panel-hover:hover .panel-body a, .panel-primary.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-success {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-success.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-success.panel-hover .panel-body:before, .panel-success.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-success.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #06db82;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-success.panel-hover .panel-body a, .panel-success.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-success.panel-hover:hover {
    border-color: #06db82; }
    .panel-success.panel-hover:hover .panel-body {
      color: white; }
      .panel-success.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-success.panel-hover:hover .panel-body a, .panel-success.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-info {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-info.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-info.panel-hover .panel-body:before, .panel-info.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-info.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #00b2e5;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-info.panel-hover .panel-body a, .panel-info.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-info.panel-hover:hover {
    border-color: #00b2e5; }
    .panel-info.panel-hover:hover .panel-body {
      color: white; }
      .panel-info.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-info.panel-hover:hover .panel-body a, .panel-info.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-danger {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-danger.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-danger.panel-hover .panel-body:before, .panel-danger.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-danger.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #f97416;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-danger.panel-hover .panel-body a, .panel-danger.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-danger.panel-hover:hover {
    border-color: #f97416; }
    .panel-danger.panel-hover:hover .panel-body {
      color: white; }
      .panel-danger.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-danger.panel-hover:hover .panel-body a, .panel-danger.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-warning {
  border: 2px solid #f4f4f4;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  box-shadow: none;
  -webkit-box-shadow: none; }
  .panel-warning.panel-hover .panel-body {
    color: #1e1e1e;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 0; }
    .panel-warning.panel-hover .panel-body:before, .panel-warning.panel-hover .panel-body:after {
      content: none;
      clear: none;
      display: block; }
    .panel-warning.panel-hover .panel-body:after {
      width: 120%;
      height: 0;
      top: 50%;
      left: 50%;
      background: #fecc00;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .panel-warning.panel-hover .panel-body a, .panel-warning.panel-hover .panel-body .text-link {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .panel-warning.panel-hover:hover {
    border-color: #fecc00; }
    .panel-warning.panel-hover:hover .panel-body {
      color: white; }
      .panel-warning.panel-hover:hover .panel-body:after {
        height: 400%;
        opacity: 1; }
      .panel-warning.panel-hover:hover .panel-body a, .panel-warning.panel-hover:hover .panel-body .text-link {
        color: white;
        text-decoration: underline; }

.panel-sm .panel-body {
  padding: 5px; }

.panel-default .panel-body {
  padding: 25px; }
  .panel-default .panel-body.insights {
    display: flex;
    flex-direction: column;
    height: 271px; }
    .panel-default .panel-body.insights p {
      flex-grow: 1; }

.panel-heading-active h3 {
  color: #4c4c4c; }

.panel-choose-install, .panel-heading-instructions, .panel-heading-verify {
  cursor: pointer; }
