.modal-backdrop {
  transition: opacity 0.1s ease-out; }

.modal {
  transition: opacity 0.1s ease-out; }
  .modal .modal-dialog {
    transition: transform 0.1s ease-out; }
  .modal.fade .modal-dialog {
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -ms-transform: scale(0.99);
    -o-transform: scale(0.99);
    transform: scale(0.99); }
  .modal.in .modal-dialog {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }

.modal-dialog:not(.modal-center) {
  padding-top: 0;
  margin-top: 60px; }
  .modal-dialog:not(.modal-center) .modal-content {
    border-top: none; }
.modal-dialog.modal-center {
  margin-top: 100px;
  width: 400px; }
  .modal-dialog.modal-center .modal-footer {
    text-align: initial; }
    .modal-dialog.modal-center .modal-footer.text-right {
      text-align: right; }
    .modal-dialog.modal-center .modal-footer.text-center {
      text-align: center; }
.modal-dialog .modal-title {
  margin-bottom: 0.5em; }
.modal-dialog .modal-content {
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  border: none; }
  .modal-dialog .modal-content .modal-header {
    padding-bottom: 0;
    border-bottom: none; }
  .modal-dialog .modal-content .modal-footer {
    padding-top: 0;
    border-top: none; }

.content-list-modal td {
  border-top: none !important;
  border-bottom: 1px solid #dddddd; }

.close {
  color: rgba(30, 30, 30, 0.5);
  opacity: 1;
  filter: none;
  transition: color 0.15s ease; }
  .close:hover, .close:focus {
    opacity: 1;
    color: #1e1e1e; }
