.redactor-box {
  border: none; }
  .redactor-box .redactor-editor {
    padding: 0;
    line-height: inherit; }

.redactor-toolbar, .redactor-editor {
  box-shadow: none;
  border: none; }

.redactor-toolbar {
  background: none; }
  .redactor-box .redactor-toolbar {
    border: none;
    padding-left: 0 !important;
    margin-bottom: 10px !important; }
  .redactor-toolbar li.redactor-toolbar-separator {
    margin: 4px 9px !important;
    border-right: none;
    height: 24px;
    border-left: 1px solid #d8d8d8; }
  .redactor-toolbar li a {
    color: #b0b0b0;
    border: none;
    padding: 9px;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transition-property: color;
    -moz-transition-property: color;
    -o-transition-property: color;
    transition-property: color; }
    .redactor-box:hover .redactor-toolbar li a {
      color: #b0b0b0; }
    .redactor-toolbar li a:hover, .redactor-toolbar li a.redactor-act {
      background-color: aliceblue;
      color: #1e1e1e !important; }

.re-filepicker:before {
  content: "\e60f"; }

.controls {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .controls .warning-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    color: #e54531;
    opacity: 0; }
