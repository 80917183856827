.hotspots {
  height: 100%; }
  .published.hotspots .preview-area {
    margin-top: 0;
    padding-top: 0; }
    .published.hotspots .preview-area .frame-wrapper, .published.hotspots .preview-area .mask {
      top: 0; }
  .hotspots .preview-area {
    min-height: 100%;
    position: relative;
    z-index: 8; }
    .hotspots .preview-area .frame-wrapper, .hotspots .preview-area .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .hotspots .preview-area .frame-wrapper iframe {
      background-color: white;
      border: none;
      width: 100%;
      height: 100%;
      display: block; }
    .hotspots .preview-area .mask {
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-transition: background-color 0.2s ease-out;
      -moz-transition: background-color 0.2s ease-out;
      -o-transition: background-color 0.2s ease-out;
      transition: background-color 0.2s ease-out;
      -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2); }
  .fullscreen-pattern.hotspots .preview-area .mask {
    background-color: #eeeeee; }
