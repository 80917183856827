.step-settings ul {
  margin: 0;
  z-index: 1600;
  font-size: 16px; }
  .step-settings ul li {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .step-settings ul li a {
      color: #7c8a94;
      display: block;
      padding: 8px;
      margin-top: 1px; }
      .step-settings ul li a:hover {
        color: #444444;
        background-color: aliceblue; }

h1.editable-title[contenteditable] {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px dashed transparent;
  -webkit-transition-property: border-bottom-color, color;
  -moz-transition-property: border-bottom-color, color;
  -o-transition-property: border-bottom-color, color;
  transition-property: border-bottom-color, color;
  -webkit-transition: 0.1s ease;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  transition: 0.1s ease;
  cursor: text;
  position: relative;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 0.5em; }
  h1.editable-title[contenteditable]:after {
    opacity: 0;
    content: "\f040";
    font-family: FontAwesome !important;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    font-size: 70%;
    bottom: 4px;
    left: 100%;
    margin-left: 5px;
    white-space: nowrap;
    color: #bbbbbb;
    pointer-events: none;
    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in; }
  h1.editable-title[contenteditable]:hover {
    color: #1f1fff; }
  h1.editable-title[contenteditable]:active, h1.editable-title[contenteditable]:focus {
    color: #5c5cff;
    border-bottom-color: #e3e3e3; }
  h1.editable-title[contenteditable]:hover:after, h1.editable-title[contenteditable]:active:after, h1.editable-title[contenteditable]:focus:after {
    opacity: 1; }
