.edit-flow {
  height: 100%; }

.content-config {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out; }
  .content-config.collapsed {
    overflow: hidden;
    max-height: 0; }
  .content-config .content {
    padding: 15px 0; }
