input.form-control.ninja-edit-field:not(.show-input) {
  outline: none;
  border: 2px solid transparent;
  padding: 4px;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }
  input.form-control.ninja-edit-field:not(.show-input):hover, input.form-control.ninja-edit-field:not(.show-input):focus {
    outline: inherit;
    border: 2px solid #dce4ec; }
  input.form-control.ninja-edit-field:not(.show-input):focus {
    border-color: #3498db; }

header input.form-control.ninja-edit-field {
  font-size: 22px;
  width: 100%;
  height: 40px; }
