.create-content {
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  text-align: center;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px; }
  .create-content .pattern-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .create-content .pattern-group .pattern-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(20% - 20px);
      min-width: 200px;
      border: 2px solid #e3e3e3;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      padding: 15px;
      margin: 0 10px 20px; }
      .create-content .pattern-group .pattern-type p {
        flex-grow: 1;
        font-weight: 200;
        line-height: 1.3em;
        margin: 0.25em 0 1.25em; }
  .create-content .close {
    font-size: 50px;
    color: #1e1e1e;
    position: absolute;
    padding: 20px;
    right: 20px;
    top: 10px;
    opacity: 0.4; }
    .create-content .close:hover {
      opacity: 1; }
  .create-content .templates {
    margin-top: 30px; }
    .create-content .templates li a {
      border: 1px solid transparent;
      border-bottom: 1px solid #e3e3e3;
      color: inherit;
      display: block;
      position: relative;
      padding: 15px; }
      .create-content .templates li a:hover, .create-content .templates li a:active, .create-content .templates li a:focus {
        border: 1px solid #5c5cff;
        background-color: #f7f7f7;
        color: inherit;
        text-decoration: none; }
        .create-content .templates li a:hover .btn-outline-primary, .create-content .templates li a:active .btn-outline-primary, .create-content .templates li a:focus .btn-outline-primary {
          border-color: #5c5cff;
          background-color: #5c5cff;
          color: white; }
      .create-content .templates li a h4 {
        margin: 0 0 5px; }
      .create-content .templates li a p {
        margin: 0; }
