.offset-controls i {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .offset-controls i:hover {
    color: #1e1e1e; }

body,
input {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

.offset-controls i {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .offset-controls i:hover {
    color: #1e1e1e; }

body,
input {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

.hover-controls {
  background-color: rgba(30, 30, 30, 0.9);
  display: none;
  flex-direction: row;
  font-size: small;
  font-weight: 400;
  padding: 3px 6px;
  position: absolute;
  text-align: center;
  bottom: 100%;
  left: -1px;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0; }
  .hover-controls a {
    color: #bbbbbb;
    cursor: pointer;
    display: flex;
    padding: 4px 6px;
    margin-right: 5px;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    white-space: nowrap; }
    .hover-controls a:last-child {
      margin-right: 0; }
    .hover-controls a:hover {
      color: white;
      text-decoration: none; }
    .hover-controls a.component-drag-handle {
      cursor: move; }
    .hover-controls a i {
      line-height: 23px; }

* {
  -webkit-font-smoothing: antialiased; }

html,
body {
  height: 100%;
  width: 100%; }

a, .text-link {
  cursor: pointer;
  color: #5c5cff; }

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong {
  font-weight: 600; }

h1 {
  font-weight: 400;
  color: #364760; }

h2 {
  color: #364760;
  font-size: 21px;
  font-weight: 700;
  padding: 10px 0 20px;
  line-height: normal;
  margin: 0;
  -webkit-margin-before: 0.83em;
  -webkit-margin-after: 0.83em; }

.flexbox {
  display: flex; }

.pull-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.affix-top {
  position: static; }

.affix-bottom {
  position: absolute; }

.remove-category {
  color: #a94442; }
  .remove-category:hover {
    color: #843534; }

.bottom-space {
  margin-bottom: 10px; }

.hint:after, [data-hint]:after {
  box-shadow: none;
  text-shadow: none;
  border-radius: 4px; }

.dashed {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 3px dashed #e3e3e3; }
  .dashed.dashed-sm {
    border-width: 1px; }
  .dashed.dashed-lg {
    border-width: 6px; }

.inline-list {
  white-space: nowrap; }

.well {
  background-image: none; }

a.well, a.panel-body {
  display: block; }
  a.well:focus, a.well:hover, a.panel-body:focus, a.panel-body:hover {
    text-decoration: none; }

.btn {
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-weight: 400; }
  .btn.btn-link {
    color: #848484;
    font-weight: 400; }
    .btn.btn-link:hover {
      color: #4c4c4c;
      text-decoration: none; }
  .btn .fa {
    margin-right: 0.5em; }
  .btn:active {
    transform: scale(0.96, 0.94);
    transition-duration: 0;
    opacity: 0.94; }

.btn-addon {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #cccccc; }

.btn-muted {
  color: #4c4c4c;
  background-color: #e3e3e3; }
  .btn-muted:hover {
    color: #4c4c4c;
    background-color: #d6d6d6; }

.btn-outline {
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 0 0 1px #e3e3e3;
  color: #848484;
  background-color: transparent;
  -webkit-transition: all, 0.15s, ease;
  -moz-transition: all, 0.15s, ease;
  -o-transition: all, 0.15s, ease;
  transition: all, 0.15s, ease; }
  .btn-outline:hover {
    border-color: #e3e3e3;
    color: white; }
  .btn-outline:hover {
    color: #4c4c4c;
    border-color: #bbbbbb; }
  .btn-outline.btn-outline-primary {
    border: 1px solid #5c5cff;
    box-shadow: inset 0 0 0 1px #5c5cff;
    color: #5c5cff;
    background-color: transparent; }
    .btn-outline.btn-outline-primary:hover {
      border-color: #5c5cff;
      color: white; }
  .btn-outline.btn-outline-success {
    border: 1px solid #06db82;
    box-shadow: inset 0 0 0 1px #06db82;
    color: #06db82;
    background-color: transparent; }
    .btn-outline.btn-outline-success:hover {
      border-color: #06db82;
      color: white; }
  .btn-outline.btn-outline-danger {
    border: 1px solid #f97416;
    box-shadow: inset 0 0 0 1px #f97416;
    color: #f97416;
    background-color: transparent; }
    .btn-outline.btn-outline-danger:hover {
      border-color: #f97416;
      color: white; }
  .btn-outline.btn-outline-warning {
    border: 1px solid #fecc00;
    box-shadow: inset 0 0 0 1px #fecc00;
    color: #fecc00;
    background-color: transparent; }
    .btn-outline.btn-outline-warning:hover {
      border-color: #fecc00;
      color: white; }

.dropdown-menu .dropdown-header {
  padding: 1rem 1rem 0.15rem;
  font-family: "Muli", "Avenir", "Futura", "Avant Garde", "Century Gothic", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  color: #5c5cff;
  letter-spacing: 0.07em; }
  .dropdown-menu .dropdown-header:first-child {
    padding-top: 0.5rem; }
.dropdown-menu .divider {
  height: 2px;
  background-color: #f4f4f4;
  margin: 8px 0 4px; }
.dropdown-menu > li > a {
  padding: 0.25rem 1rem; }
.dropdown-menu > li:not(.dropdown-header) + li.dropdown-header {
  padding-top: 1rem; }

.fa-accent.fa-twitter, .fa-accent.fa-twitter-square {
  color: #46c0fb; }
.fa-accent.fa-facebook, .fa-accent.fa-facebook-square {
  color: #4863ae; }
.fa-accent.fa-linkedin, .fa-accent.fa-linkedin-square {
  color: #0083a8; }

.with-offset-controls {
  margin-right: 45px; }

.offset-controls {
  line-height: 30px;
  top: 1rem;
  right: -3rem;
  position: absolute;
  width: 36px;
  margin-bottom: 0; }
  .offset-controls li {
    margin-bottom: 1rem;
    max-height: 28px; }
    .offset-controls li.divider {
      border-top: 1px solid #cccccc; }

hr[data-content] {
  margin: 30px 0;
  text-align: center; }
  hr[data-content]:after {
    content: attr(data-content);
    position: relative;
    top: -1rem;
    background-color: white;
    border-radius: 0;
    border: 1px solid #eeeeee;
    padding: 1px 9px 1px;
    text-align: center;
    display: inline-block;
    font-size: 85%; }

.checkbox-toggle {
  display: none; }

input.checkbox-toggle:checked + .checkbox-toggle {
  display: block; }

.input-group .input-group-floater {
  position: absolute;
  top: 6px;
  right: 6px; }

.btn-toggle .fa-check {
  color: #e3e3e3; }
.btn-toggle.toggle-on .fa-check {
  color: #06db82; }

.fa-copy.fa-fw.fa-lg {
  font-size: 1.2em; }

.list-group-item.draggable-list-group-item {
  position: relative; }
  .list-group-item.draggable-list-group-item.sortable-ghost {
    background-color: #f8f8f8; }
    .list-group-item.draggable-list-group-item.sortable-ghost .drag-handle {
      background-color: #f8f8f8; }
  .list-group-item.draggable-list-group-item .drag-handle {
    width: 9px;
    height: 50%;
    cursor: move;
    background-color: white;
    background-image: linear-gradient(90deg, #cccccc 50%, rgba(255, 255, 255, 0.5) 50%);
    background-size: 3px 3px;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 25%; }

ol.checklist li.done {
  text-decoration: line-through;
  color: #cccccc; }

table.table-layout {
  width: 100%; }
  table.table-layout td {
    vertical-align: top; }

table.table-padded {
  width: 100%; }
  table.table-padded td {
    padding: 1rem; }

.display-table {
  display: table;
  width: 100%; }
  .display-table .table-cell {
    display: table-cell; }
  .display-table .cell-space {
    padding: 0 10px; }

.radio-reveal .radio-content {
  padding-left: 20px; }

.flex-row {
  display: flex;
  margin-right: -2rem;
  flex-wrap: wrap; }

.flex-col {
  flex: 1;
  margin-right: 2rem; }

.status-dot {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px; }
  .status-dot.status-critical {
    background-color: #f97416; }
  .status-dot.status-major {
    background-color: #f97416; }
  .status-dot.status-minor {
    background-color: #fecc00; }
  .status-dot.status-none {
    background-color: #06db82; }
