.build-flow #build-preview-frame iframe.cocoon {
  top: 0; }
.build-flow #build-preview-frame .preview-area.sandboxed {
  margin-top: 0;
  padding-top: 0; }

.step-container .appcues-backdrop[data-pattern-type='fullscreen'], .step-container .appcues-backdrop[data-pattern-type='modal'], .step-container .appcues-backdrop[data-pattern-type='shorty'], .step-container .appcues-backdrop[data-pattern-type='left'] {
  z-index: 5;
  animation: none; }
.step-container appcues[data-pattern-type='modal'].active, .step-container appcues[data-pattern-type='left'].active, .step-container appcues[data-pattern-type='fullscreen'].active, .step-container appcues[data-pattern-type='shorty'].active {
  animation: none;
  z-index: 6; }
.step-container appcues[data-pattern-type='left'].active {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  z-index: 11; }
.step-container appcues[data-pattern-type='shorty'] modal-container {
  box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.3); }
  .step-container appcues[data-pattern-type='shorty'] modal-container cue section {
    margin-bottom: 35px !important;
    position: relative; }
    .step-container appcues[data-pattern-type='shorty'] modal-container cue section div.add-component {
      position: absolute;
      left: 50%;
      margin-left: -17px;
      bottom: -40px; }
.step-container appcues div[contenteditable=true]:focus {
  outline: none; }
.step-container appcues .btn-muted {
  color: #7b8a8b;
  background-color: #ecf0f1; }
  .step-container appcues .btn-muted:hover {
    color: #7b8a8b;
    background-color: #dde4e6; }
.step-container appcues .redactor-box {
  margin: 0;
  border: none;
  padding: 0; }
  .step-container appcues .redactor-box .redactor-editor {
    margin: 0;
    border: none;
    padding: 0;
    overflow: inherit; }
.step-container appcues:not(.cue-step-0) cue .appcues-button[data-step="prev"] {
  display: inline-block; }
.step-container appcues .form-field .col-md-6 {
  padding: 0; }
.step-container appcues .form-field .row {
  margin: 0; }

body {
  background: none; }
