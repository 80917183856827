.setting-panel:last-child {
  margin-bottom: 150px; }
.setting-panel .panel-heading {
  border: none;
  cursor: pointer;
  padding: 10px 15px; }
  .setting-panel .panel-heading .title {
    font-size: 14px;
    line-height: 24px;
    -webkit-transition: color 0.1s ease-out;
    -moz-transition: color 0.1s ease-out;
    -o-transition: color 0.1s ease-out;
    transition: color 0.1s ease-out; }
  .setting-panel .panel-heading .summary {
    float: right;
    line-height: 24px;
    color: #09bb71; }
.setting-panel.open .title, .setting-panel:hover .title {
  color: #1e1e1e; }
.setting-panel .panel-body {
  display: none;
  padding: 15px; }
  .setting-panel .panel-body .radio, .setting-panel .panel-body .checkbox {
    margin: 10px 0; }
    .setting-panel .panel-body .radio:last-child, .setting-panel .panel-body .checkbox:last-child {
      margin-bottom: 0px; }
    .setting-panel .panel-body .radio input[type=radio], .setting-panel .panel-body .radio input[type=checkbox], .setting-panel .panel-body .checkbox input[type=radio], .setting-panel .panel-body .checkbox input[type=checkbox] {
      position: absolute;
      left: -9999px; }
    .setting-panel .panel-body .radio input[type=radio] + span, .setting-panel .panel-body .radio input[type=checkbox] + span, .setting-panel .panel-body .checkbox input[type=radio] + span, .setting-panel .panel-body .checkbox input[type=checkbox] + span {
      background-color: white;
      border: 1px solid #cccccc;
      content: "";
      display: inline-block;
      height: 18px;
      margin: 4px 10px 0 -20px;
      position: relative;
      vertical-align: -0.25em;
      width: 18px;
      -webkit-transition: border-color 0.1s ease-out;
      -moz-transition: border-color 0.1s ease-out;
      -o-transition: border-color 0.1s ease-out;
      transition: border-color 0.1s ease-out; }
    .setting-panel .panel-body .radio input[type=radio]:disabled + span, .setting-panel .panel-body .radio input[type=checkbox]:disabled + span, .setting-panel .panel-body .checkbox input[type=radio]:disabled + span, .setting-panel .panel-body .checkbox input[type=checkbox]:disabled + span {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
      opacity: 0.6;
      cursor: not-allowed; }
    .setting-panel .panel-body .radio input[type=radio]:checked + span::before, .setting-panel .panel-body .checkbox input[type=radio]:checked + span::before {
      background-color: #666666;
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -4px;
      height: 8px;
      width: 8px;
      -webkit-transition: background-color 0.1s ease-out;
      -moz-transition: background-color 0.1s ease-out;
      -o-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out; }
    .setting-panel .panel-body .radio input[type=checkbox]:checked + span::before, .setting-panel .panel-body .checkbox input[type=checkbox]:checked + span::before {
      color: #666666;
      content: "\f00c";
      display: inline-block;
      font-family: FontAwesome;
      font-size: 15px;
      height: 15px;
      left: 50%;
      margin-top: -10px;
      margin-left: -7px;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 15px; }
    .setting-panel .panel-body .radio input[type=radio]:focus + span, .setting-panel .panel-body .checkbox input[type=radio]:focus + span {
      border-color: #5c5cff; }
      .setting-panel .panel-body .radio input[type=radio]:focus + span::before, .setting-panel .panel-body .checkbox input[type=radio]:focus + span::before {
        background-color: #5c5cff; }
    .setting-panel .panel-body .radio input[type=radio] + span, .setting-panel .panel-body .checkbox input[type=radio] + span {
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      -ms-border-radius: 18px;
      -o-border-radius: 18px;
      border-radius: 18px; }
    .setting-panel .panel-body .radio input[type=radio]:checked + span::before, .setting-panel .panel-body .checkbox input[type=radio]:checked + span::before {
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px; }
    .setting-panel .panel-body .radio input[type=checkbox] + span, .setting-panel .panel-body .checkbox input[type=checkbox] + span {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px; }
    .setting-panel .panel-body .radio input[type=checkbox]:checked + span::before, .setting-panel .panel-body .checkbox input[type=checkbox]:checked + span::before {
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
      height: 10px;
      width: 10px; }
    .setting-panel .panel-body .radio input[type=checkbox]:focus + span, .setting-panel .panel-body .checkbox input[type=checkbox]:focus + span {
      border-color: #5c5cff; }
      .setting-panel .panel-body .radio input[type=checkbox]:focus + span::before, .setting-panel .panel-body .checkbox input[type=checkbox]:focus + span::before {
        color: #5c5cff; }
    .setting-panel .panel-body .radio .subtext, .setting-panel .panel-body .checkbox .subtext {
      color: #888888;
      margin-bottom: 5px;
      margin-left: 4px; }
  .setting-panel .panel-body .content {
    display: none;
    margin: 10px 0;
    padding-left: 25px; }
  .setting-panel .panel-body .active > .content,
  .setting-panel .panel-body .content.active {
    display: block; }
    .setting-panel .panel-body .active > .content:last-child,
    .setting-panel .panel-body .content.active:last-child {
      margin-bottom: 0; }
    .setting-panel .panel-body .active > .content > *:last-child,
    .setting-panel .panel-body .content.active > *:last-child {
      margin-bottom: 0; }
.setting-panel.open .panel-body {
  display: block; }

.setting-panel .select-domains {
  margin-top: 0 !important; }
  .setting-panel .select-domains .checkbox {
    margin: 0 0 5px; }

.selectize-control .content-option .content-name, .selectize-control .content-option .content-id, .selectize-control .content-option .content-status- {
  display: inline-block;
  padding: 1px 0; }
.selectize-control .content-option .content-id {
  color: #fcfcfc;
  margin-left: 0.5em; }
.selectize-control .content-option .content-status {
  float: right;
  text-align: right; }
  .selectize-control .content-option .content-status.text-success {
    color: #64a71a; }

.prehook-editor {
  min-height: 320px; }
