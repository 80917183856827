.content-list img.captain-cues {
  margin: 60px 0 20px; }
.content-list h4.accent {
  font-size: 18px;
  color: #5c5cff;
  margin: 10px 0 20px; }
.content-list .create-button {
  padding: 10px 40px; }
.content-list .help-section {
  margin: 60px 0 20px; }
  .content-list .help-section .well {
    box-shadow: none;
    display: inline-block;
    padding: 10px 40px;
    background-color: #f7f7f7;
    border: 0.5px solid #dddddd; }
    .content-list .help-section .well h5 {
      font-size: 16px;
      font-weight: 400; }
      .content-list .help-section .well h5 i.accent {
        font-size: 14px;
        color: #5c5cff;
        margin-right: 4px; }
    .content-list .help-section .well .list-links {
      margin: 15px 0 10px; }
      .content-list .help-section .well .list-links a {
        margin: 0 10px; }
.content-list .footer-section {
  margin-top: 30px; }

.content-group div.group-header {
  margin: 0 0 1rem;
  position: relative;
  cursor: pointer; }
  .content-group div.group-header > h4 {
    background-color: white;
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    padding: 0 10px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #bbbbbb;
    letter-spacing: 0.05em;
    transition: color 0.2s ease; }
  .content-group div.group-header:hover h4 {
    color: #848484; }
  .content-group div.group-header span.fa-chevron-down, .content-group div.group-header span.fa-chevron-right {
    font-size: 80%;
    vertical-align: text-top;
    line-height: 1.4em;
    margin-right: 0.5em; }
  .content-group div.group-header:before {
    content: "";
    background-color: #f4f4f4;
    height: 2px;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    border-radius: 3px; }
  .content-group div.group-header > small {
    line-height: 1.5em;
    position: relative;
    display: inline-block;
    background-color: white;
    padding-left: 10px; }
    .content-group div.group-header > small > span, .content-group div.group-header > small > a {
      border: 2px solid #e3e3e3;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      padding: 2px 8px 2px;
      font-size: 11px;
      color: #4c4c4c;
      line-height: 1rem;
      display: inline-block;
      height: 23px; }
    .content-group div.group-header > small > a {
      font-size: 16px; }
      .content-group div.group-header > small > a:hover {
        color: #5c5cff;
        text-decoration: none;
        border-color: #5c5cff; }
.content-group .drop-zone {
  display: none; }
.content-group:hover .drop-zone {
  content: "test";
  display: block;
  min-height: 100px;
  border: 3px dashed #e3e3e3;
  text-align: center; }

.hotspots-alert {
  color: #f97416; }

.content-table {
  margin-bottom: 30px;
  vertical-align: top; }
  .content-table .one-liner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block; }
  .content-table tr {
    transition: background-color 300ms;
    border-bottom: 2px solid #f4f4f4;
      border-bottom--webkit-transition: background-color 0.15s ease;
      border-bottom--moz-transition: background-color 0.15s ease;
      border-bottom--o-transition: background-color 0.15s ease;
      border-bottom-transition: background-color 0.15s ease; }
    .content-table tr:hover {
      background-color: #f4f4f4; }
    .content-table tr.deleting {
      background-color: #fff1e8; }
    .content-table tr.archiving {
      background-color: #fef6d9; }
    .content-table tr.unarchiving {
      background-color: #def7ff; }
    .content-table tr.cloning {
      background-color: #defaee; }
    .content-table tr:last-child {
      border-bottom: 0; }
    .content-table tr .on-hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-in-out;
      -moz-transition: opacity 0.2s ease-in-out;
      -o-transition: opacity 0.2s ease-in-out;
      transition: opacity 0.2s ease-in-out; }
    .content-table tr:hover .on-hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
    .content-table tr td {
      padding-top: 20px;
      padding-bottom: 20px; }
      .content-table tr td .hint--bottom:before, .content-table tr td .hint--bottom:after {
        left: 30%; }
      .content-table tr td a {
        color: inherit; }
        .content-table tr td a:hover {
          color: #5c5cff;
          text-decoration: none; }
    .content-table tr td.icon {
      padding: 0.75rem 1rem;
      text-align: center;
      width: 90px; }
    .content-table tr td.details h3 {
      font-size: 18px;
      margin: 0 0 10px; }
    .content-table tr td.details .img-avatar {
      margin-right: 8px; }
    .content-table tr td.details small {
      font-size: 13px;
      font-weight: 400; }
    .content-table tr td.stats, .content-table tr td.target, .content-table tr td.status {
      width: 115px; }
    .content-table tr td.status .label {
      display: inline-block;
      min-width: 70px; }
    .content-table tr td.action {
      width: 30px;
      font-size: 16px; }
      .content-table tr td.action a {
        color: inherit; }
        .content-table tr td.action a:hover {
          color: #5c5cff; }
      .content-table tr td.action a[data-action=archive]:hover {
        color: #973f02; }
      .content-table tr td.action .on-hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
        opacity: 0.15; }
    .content-table tr:hover td.action .on-hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }

.category-areas {
  display: none; }
  .category-areas .category-area {
    background-color: #f7f7f7;
    border: 2px dashed #e3e3e3;
    min-height: 80px;
    padding: 20px 0 10px;
    width: 100%;
    position: relative;
    z-index: 999999; }
    .category-areas .category-area:before {
      color: #e3e3e3;
      content: attr(data-label);
      position: absolute;
      text-align: center;
      font-size: inherit;
      top: 10px;
      width: 100%;
      -webkit-transition: background-color 0.1s ease-out;
      -moz-transition: background-color 0.1s ease-out;
      -o-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out;
      -webkit-transition: top 0.1s ease-out;
      -moz-transition: top 0.1s ease-out;
      -o-transition: top 0.1s ease-out;
      transition: top 0.1s ease-out;
      -webkit-transition: font-size 0.1s ease-out;
      -moz-transition: font-size 0.1s ease-out;
      -o-transition: font-size 0.1s ease-out;
      transition: font-size 0.1s ease-out; }
    .category-areas .category-area:empty {
      background-color: white; }
    .category-areas .category-area:empty:before {
      content: attr(data-empty-label);
      font-size: large;
      line-height: 1em;
      margin-top: -0.5em;
      top: 50%; }

.col-top-padding {
  padding-top: 7px; }
