.floating-cta {
  position: fixed;
  left: 20px;
  bottom: 20px;
  max-width: 400px;
  -webkit-box-shadow: 0 -20px 20px white;
  -moz-box-shadow: 0 -20px 20px white;
  box-shadow: 0 -20px 20px white; }
  .floating-cta .panel {
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .floating-cta .profile-info {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    text-align: center; }
    .floating-cta .profile-info img {
      margin: 0 5px;
      border: 1px solid #cccccc;
      vertical-align: top;
      background: white; }
    .floating-cta .profile-info > span {
      margin-top: 45px;
      display: inline-block; }
    .floating-cta .profile-info + .message-body {
      margin-top: 25px; }
  .floating-cta .message-body {
    margin-bottom: 20px; }
  .floating-cta .powered-by {
    position: absolute;
    bottom: 10px;
    right: 10px; }
