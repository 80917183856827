.footer-container {
  background-color: #4c4c4c;
  height: 100%; }

.foot {
  color: #e3e3e3;
  padding-top: 10px;
  padding-bottom: 5px;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .foot a {
    color: #e3e3e3; }
    .foot a:hover {
      color: #bbbbbb; }
