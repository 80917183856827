.component-preview {
  border: 1px dashed transparent;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: border-color 0.2s ease-out;
  -moz-transition: border-color 0.2s ease-out;
  -o-transition: border-color 0.2s ease-out;
  transition: border-color 0.2s ease-out; }
  .component-preview.hover.editable {
    border-color: rgba(30, 30, 30, 0.9);
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px; }
    .component-preview.hover.editable .hover-controls {
      display: flex;
      z-index: 2;
      -webkit-animation-name: fadeInFromNone;
      animation-name: fadeInFromNone;
      -webkit-animation-duration: 0.2s;
      animation-duration: 0.2s;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
  .component-preview.empty {
    border: 1px dashed #e3e3e3;
    display: none;
    margin-bottom: 10px; }
    .component-preview.empty .empty-component {
      color: #e3e3e3;
      cursor: pointer;
      padding: 15px 0; }
  .component-preview.empty.editable {
    display: block; }

appcues cue:hover .add-component, .step cue:hover .add-component {
  display: block; }
appcues cue .empty-state, .step cue .empty-state {
  display: none;
  text-align: center; }
appcues cue .add-component, .step cue .add-component {
  opacity: 0.8;
  transition: opacity 1s;
  margin: 5px 0 -10px;
  position: relative;
  text-align: center;
  z-index: 4;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }
  appcues cue .add-component:hover, .step cue .add-component:hover {
    opacity: 1; }
  appcues cue .add-component.open .choose-component-types, .step cue .add-component.open .choose-component-types {
    display: block; }
  appcues cue .add-component .add-icon, .step cue .add-component .add-icon {
    border: 2px dashed #06db82;
    color: #06db82;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    height: 34px;
    line-height: 34px;
    width: 34px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    -webkit-transition: border-color 0.2s ease-out;
    -moz-transition: border-color 0.2s ease-out;
    -o-transition: border-color 0.2s ease-out;
    transition: border-color 0.2s ease-out;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    appcues cue .add-component .add-icon:hover, .step cue .add-component .add-icon:hover {
      border-color: #4c4c4c;
      color: #4c4c4c; }
  appcues cue .add-component .choose-component-types, .step cue .add-component .choose-component-types {
    display: none;
    left: -16px;
    margin-top: -3px;
    position: absolute;
    right: -16px;
    top: 100%;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown; }
    appcues cue .add-component .choose-component-types:after, .step cue .add-component .choose-component-types:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: rgba(30, 30, 30, 0.95);
      border-width: 10px;
      margin-left: -10px;
      margin-bottom: -15px; }
    appcues cue .add-component .choose-component-types img.pacman-icon, .step cue .add-component .choose-component-types img.pacman-icon {
      height: 36px;
      width: 36px;
      margin-bottom: 8px; }

.choose-component-types {
  text-align: center; }
  .choose-component-types .component-types {
    background-color: rgba(30, 30, 30, 0.95);
    position: relative;
    margin: 15px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px; }
    .choose-component-types .component-types h4 {
      color: inherit;
      font-weight: inherit;
      margin: 0;
      padding-top: 5px; }
    .choose-component-types .component-types a {
      color: #e3e3e3;
      cursor: pointer;
      display: block;
      padding: 15px;
      text-decoration: none;
      line-height: 1.2em;
      -webkit-transition: color 0.2s ease-out;
      -moz-transition: color 0.2s ease-out;
      -o-transition: color 0.2s ease-out;
      transition: color 0.2s ease-out; }
      .choose-component-types .component-types a i {
        display: block;
        font-size: 36px;
        margin-bottom: 8px;
        -webkit-transition: color 0.2s ease-out;
        -moz-transition: color 0.2s ease-out;
        -o-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out; }
      .choose-component-types .component-types a:hover {
        color: white; }
        .choose-component-types .component-types a:hover i {
          color: #5c5cff; }

.component-editor-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.8);
  z-index: 1000;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

appcues[data-position^="bottom"] .component-editor {
  bottom: 0;
  top: auto; }
appcues[data-position^="bottom"] cue .add-component .choose-component-types {
  top: auto;
  bottom: 100%;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }
  appcues[data-position^="bottom"] cue .add-component .choose-component-types:after {
    transform: rotate(180deg);
    top: 121px;
    bottom: auto; }

appcues[data-pattern-type="shorty"] .component-preview .component-editor-mask {
  display: none; }
appcues[data-pattern-type="shorty"] .component-preview .component-editor {
  margin: auto;
  top: auto;
  bottom: 0;
  box-shadow: 0px 0px 50px black; }
  appcues[data-pattern-type="shorty"] .component-preview .component-editor .preview .apc-img-wrap a img {
    max-height: 200px; }
  appcues[data-pattern-type="shorty"] .component-preview .component-editor .redactor-box .redactor-toolbar li {
    margin: 0 -4px !important; }
    appcues[data-pattern-type="shorty"] .component-preview .component-editor .redactor-box .redactor-toolbar li a {
      font-size: 8pt; }
appcues[data-pattern-type="shorty"] cue .add-component .choose-component-types {
  width: 400px;
  margin-left: -167px; }

.component-editor {
  background-color: white;
  margin: -10px -21px;
  opacity: 0.95;
  margin-bottom: 80px;
  padding: 10px 21px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1001;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
  .component-editor [contenteditable]:focus, .component-editor [contenteditable]:active, .component-editor [contenteditable=true]:focus, .component-editor [contenteditable=true]:active {
    outline: none;
    border: none; }
  .component-editor [contenteditable][placeholder]:empty:before, .component-editor [contenteditable=true][placeholder]:empty:before {
    content: attr(placeholder);
    opacity: 0.7; }
  .component-editor.editor-component {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .component-editor .redactor-box, .component-editor .redactor-editor {
    background: transparent;
    height: 100%;
    margin: 0; }
    .component-editor .redactor-box .redactor-toolbar, .component-editor .redactor-editor .redactor-toolbar {
      background-color: rgba(30, 30, 30, 0.99);
      height: 40px;
      margin: 0 -21px !important;
      left: 0;
      padding: 0px !important;
      position: absolute;
      right: 0;
      text-align: center;
      top: -50px;
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px; }
      .component-editor .redactor-box .redactor-toolbar li, .component-editor .redactor-editor .redactor-toolbar li {
        margin: 0 2px !important; }
        .component-editor .redactor-box .redactor-toolbar li a, .component-editor .redactor-editor .redactor-toolbar li a {
          color: #98a3ab !important;
          padding: 12px 10px !important;
          font-family: RedactorFont !important;
          -webkit-transition: background-color 0.1s ease-out;
          -moz-transition: background-color 0.1s ease-out;
          -o-transition: background-color 0.1s ease-out;
          transition: background-color 0.1s ease-out; }
          .component-editor .redactor-box .redactor-toolbar li a:hover, .component-editor .redactor-box .redactor-toolbar li a:hover i:before, .component-editor .redactor-editor .redactor-toolbar li a:hover, .component-editor .redactor-editor .redactor-toolbar li a:hover i:before {
            background-color: #4c4c4c !important;
            color: #98a3ab !important; }
    .component-editor .redactor-box textarea, .component-editor .redactor-editor textarea {
      font-family: "source-code-pro", Menlo, Monaco, Consolas, "Courier New", monospace;
      min-height: 240px;
      padding: 4px 6px;
      resize: vertical; }
  .component-editor .controls {
    background-color: rgba(247, 247, 247, 0.99);
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    padding: 5px;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
    .component-editor .controls.no-animation {
      -webkit-animation-name: none;
      animation-name: none;
      -webkit-animation-duration: 0;
      animation-duration: 0; }
    .component-editor .controls .btn {
      margin-left: 5px;
      text-decoration: none; }
      .component-editor .controls .btn.btn-link-danger {
        color: #f97416; }
        .component-editor .controls .btn.btn-link-danger:hover {
          color: #a54704; }

.redactor-toolbar-tooltip {
  margin-top: 7px; }
  .redactor-toolbar-tooltip::before {
    background: transparent;
    border: 6px solid transparent;
    border-bottom-color: black;
    content: "";
    left: calc(50% - 5px);
    margin-top: -10px;
    position: absolute;
    top: 0px;
    z-index: 1053; }

.image-component form.drop-area {
  border: 2px dashed #bbbbbb;
  color: #4c4c4c;
  margin: 15px;
  padding: 40px 0;
  text-align: center;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: border-color 0.2s ease-out;
  -moz-transition: border-color 0.2s ease-out;
  -o-transition: border-color 0.2s ease-out;
  transition: border-color 0.2s ease-out; }
  .image-component form.drop-area.dz-drag-hover {
    border-color: #4c4c4c; }
    .image-component form.drop-area.dz-drag-hover .divider, .image-component form.drop-area.dz-drag-hover .dropzone-btn {
      opacity: 0; }
  .image-component form.drop-area .dz-message {
    display: none; }
  .image-component form.drop-area .message {
    font-size: large; }
  .image-component form.drop-area .divider {
    color: #e3e3e3;
    display: block;
    margin: 15px 0; }
  .image-component form.drop-area .divider, .image-component form.drop-area .dropzone-btn {
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out; }
.image-component .uploading {
  color: #e3e3e3;
  padding: 50px;
  text-align: center; }
.image-component .preview {
  margin-bottom: 5px; }
.image-component .settings {
  padding: 10px; }
  .image-component .settings .row {
    margin-bottom: 5px; }

.video-component .tips {
  padding: 5px; }
  .video-component .tips ul {
    list-style: none;
    padding-left: 0; }
.video-component .embed-code label {
  font-weight: normal; }
.video-component .embed-code textarea {
  font-family: "source-code-pro", Menlo, Monaco, Consolas, "Courier New", monospace;
  resize: vertical;
  white-space: pre; }

.appcues-style {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-size: 14px; }
