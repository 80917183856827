.quickstart-page {
  padding: 40px 0; }
  .quickstart-page h1 {
    color: #0098c2;
    font-size: 30px; }
  .quickstart-page h2 {
    font-size: 18px;
    color: #5c5cff;
    font-weight: 400;
    margin: 5px 0 35px 0; }
  .quickstart-page label {
    font-weight: normal;
    float: left;
    color: #848484;
    font-size: 88%;
    font-weight: 600; }
  .quickstart-page .logo {
    margin-bottom: 40px; }
  .quickstart-page .form-control {
    color: #4c4c4c !important; }
    .quickstart-page .form-control.form-control-danger {
      border-color: #fecc00; }
  .quickstart-page .form-control-feedback {
    position: relative;
    width: 100%;
    font-style: italic;
    height: auto;
    font-size: 14px;
    padding-top: 8px;
    line-height: 20px;
    color: #fecc00; }
  .quickstart-page .btn.btn-success {
    padding: 14px 20px;
    font-family: "Muli", "Avenir", "Futura", "Avant Garde", "Century Gothic", Helvetica, Arial, sans-serif;
    letter-spacing: 0.07em; }
