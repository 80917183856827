.edit-info {
  padding: 10px 0; }
  .edit-info header, .edit-info .buttons {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap; }
  .edit-info header {
    padding: 0;
    width: 100%; }
    .edit-info header input {
      width: 100%; }
    .edit-info header + .buttons {
      padding-left: 10px; }
  .edit-info .publish-btn-wrapper {
    display: inline-block;
    position: relative; }
    .edit-info .publish-btn-wrapper .publish-btn.with-warnings {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=65);
      opacity: 0.65; }
    .edit-info .publish-btn-wrapper .error-count {
      background-color: #ec7364;
      border-radius: 16px;
      color: white;
      font-size: 11px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      width: 16px;
      position: absolute;
      top: -4px;
      right: -4px;
      -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      -webkit-animation-duration: 0.25s;
      animation-duration: 0.25s;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out; }
    .edit-info .publish-btn-wrapper .error-checklist-wrapper {
      display: none;
      padding-top: 10px;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 1100; }
    .edit-info .publish-btn-wrapper:hover .error-checklist-wrapper {
      display: block; }
    .edit-info .publish-btn-wrapper .error-checklist {
      background-color: white;
      border: 1px solid gainsboro;
      border-radius: 5px;
      position: relative;
      width: 320px;
      -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); }
      .edit-info .publish-btn-wrapper .error-checklist:after {
        bottom: 100%;
        right: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(149, 213, 164, 0);
        border-bottom-color: gainsboro;
        border-width: 10px; }
      .edit-info .publish-btn-wrapper .error-checklist ol {
        margin: 0; }
        .edit-info .publish-btn-wrapper .error-checklist ol li {
          border-top: 1px solid #eeeeee;
          padding: 10px; }
          .edit-info .publish-btn-wrapper .error-checklist ol li h5 {
            font-weight: bold;
            margin: 0 0 5px; }
          .edit-info .publish-btn-wrapper .error-checklist ol li p {
            font-size: small;
            margin: 0;
            white-space: normal; }

.content-config {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out; }
  .content-config.collapsed {
    overflow: hidden;
    max-height: 0; }
  .content-config .content {
    padding: 15px 0; }
