.thumbnails-list-sequence .thumbnail-item.sequential:after {
  content: "\f105";
  font-size: 20px;
  line-height: 20px;
  color: #999999;
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  right: -16px;
  margin-top: -10px; }
.thumbnails-list-sequence .thumbnail-item.sequential:last-child:after {
  content: ""; }

.thumbnails-list .thumbnail-item {
  border-width: 2px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
    margin-right: 22px;
  padding: 5px 3px;
  position: relative;
  -webkit-transition-property: border-color, border-width;
  -moz-transition-property: border-color, border-width;
  -o-transition-property: border-color, border-width;
  transition-property: border-color, border-width;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); }
  .thumbnails-list .thumbnail-item:hover {
    border-color: rgba(52, 152, 219, 0.5); }
  .thumbnails-list .thumbnail-item.selected {
    border: 2px solid #3498db;
    border-right-width: 32px; }
    .thumbnails-list .thumbnail-item.selected:after {
      right: -47px; }
    .thumbnails-list .thumbnail-item.selected .thumbnail-controls {
      display: block; }
  .thumbnails-list .thumbnail-item.dashed {
    opacity: 0.4;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    color: #e3e3e3;
    cursor: default; }
  .thumbnails-list .thumbnail-item .thumbnail-controls {
    display: none;
    position: absolute;
    top: 0;
    left: 100%; }
    .thumbnails-list .thumbnail-item .thumbnail-controls a {
      color: white;
      display: block;
      margin: 6px 3px;
      padding: 3px;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out; }
      .thumbnails-list .thumbnail-item .thumbnail-controls a:hover {
        opacity: 1; }
