div.step > .step-action, cue.step-action {
  min-height: 400px;
  padding-bottom: 60px;
  position: relative; }
  div.step > .step-action > .dashed, cue.step-action > .dashed {
    padding: 2em; }
  div.step > .step-action .beta-label, cue.step-action .beta-label {
    padding: 0.4em 0.7em;
    position: absolute;
    top: 0px;
    right: 15px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0; }
  div.step > .step-action .video-placeholder, cue.step-action .video-placeholder {
    background: url("../images/video-placeholder.jpg") no-repeat center center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 300px;
    color: rgba(255, 255, 255, 0.6);
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    text-align: center;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transition-property: color;
    -moz-transition-property: color;
    -o-transition-property: color;
    transition-property: color;
    cursor: pointer; }
    div.step > .step-action .video-placeholder:hover, cue.step-action .video-placeholder:hover {
      color: rgba(255, 255, 255, 0.9); }
    div.step > .step-action .video-placeholder .fa, cue.step-action .video-placeholder .fa {
      font-size: 100px;
      line-height: 300px; }
  div.step > .step-action .image-uploader, cue.step-action .image-uploader {
    width: 100%;
    cursor: pointer;
    padding: 50px;
    text-align: center;
    border: 3px dashed transparent;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transition-property: color, border-color;
    -moz-transition-property: color, border-color;
    -o-transition-property: color, border-color;
    transition-property: color, border-color;
    height: 250px;
    color: #8f8fff;
    overflow: hidden; }
    div.step > .step-action .image-uploader:after, cue.step-action .image-uploader:after {
      position: absolute;
      top: -1%;
      right: -1%;
      bottom: -1%;
      left: -1%;
      content: "";
      background-image: url("../images/henry-ford.jpg");
      background-size: cover;
      -webkit-transition: all 300ms ease-out;
      -moz-transition: all 300ms ease-out;
      -o-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      -webkit-transition-property: top, right, bottom, left;
      -moz-transition-property: top, right, bottom, left;
      -o-transition-property: top, right, bottom, left;
      transition-property: top, right, bottom, left;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
      opacity: 0.1; }
    div.step > .step-action .image-uploader:hover, cue.step-action .image-uploader:hover {
      border-color: #dddddd;
      color: #5c5cff; }
      div.step > .step-action .image-uploader:hover:after, cue.step-action .image-uploader:hover:after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
  div.step > .step-action .redactor_editor, div.step > .step-action textarea.redactor, cue.step-action .redactor_editor, cue.step-action textarea.redactor {
    min-height: 320px; }
    div.step > .step-action .redactor_editor.small, div.step > .step-action .redactor_editor.redactor_small, div.step > .step-action textarea.redactor.small, div.step > .step-action textarea.redactor.redactor_small, cue.step-action .redactor_editor.small, cue.step-action .redactor_editor.redactor_small, cue.step-action textarea.redactor.small, cue.step-action textarea.redactor.redactor_small {
      min-height: 100px; }
  div.step > .step-action .ace-editor, cue.step-action .ace-editor {
    min-height: 400px;
    border: 1px solid #dddddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
  div.step > .step-action i.fa, cue.step-action i.fa {
    font-family: FontAwesome !important; }

div.step .step-action-content {
  height: 100%; }
  div.step .step-action-content img {
    max-width: 100%;
    width: auto; }

.step-action-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3; }
  .step-action-buttons .appcues-button[data-step='prev']:before, .step-action-buttons .appcues-button[data-step='next']:after {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 8px 10px;
    line-height: 20px; }
  .step-action-buttons .appcues-button {
    background-color: #cccccc;
    cursor: pointer;
    padding: 8px 18px;
    color: white;
    font-weight: bold;
    display: inline-block;
    position: relative;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out; }
    .step-action-buttons .appcues-button:hover {
      text-decoration: none;
      color: black;
      background-color: #dedede; }
    .step-action-buttons .appcues-button.appcues-button-success {
      background-color: #5cb85c; }
      .step-action-buttons .appcues-button.appcues-button-success:hover {
        background-color: #75c375; }
      .step-action-buttons .appcues-button.appcues-button-success.disabled {
        background-color: #eaf6ea; }
      .step-action-buttons .appcues-button.appcues-button-success [contenteditable]::-moz-selection {
        background: rgba(255, 255, 255, 0.6); }
      .step-action-buttons .appcues-button.appcues-button-success [contenteditable]::selection {
        background: rgba(255, 255, 255, 0.6); }
    .step-action-buttons .appcues-button[data-step='prev'] {
      padding-left: 45px; }
      .step-action-buttons .appcues-button[data-step='prev']:before {
        left: 0;
        content: "\25C0";
        border-right: 1px solid white; }
    .step-action-buttons .appcues-button[data-step='next'] {
      padding-right: 45px; }
      .step-action-buttons .appcues-button[data-step='next']:after {
        right: 0;
        content: "\25B6";
        border-left: 1px solid white; }
  .step-action-buttons .row:after {
    content: none; }
  .steps .step.first .step-action-buttons .appcues-button[data-step="prev"] {
    display: none; }
  .steps .step.last .step-action-buttons .appcues-button[data-step="next"] {
    display: none; }
  .steps .step.last .step-action-buttons .appcues-button[data-step="end"] {
    display: inline-block; }
  .step-action-buttons .add-icon {
    border: 1px dashed #e3e3e3;
    color: #e3e3e3;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    height: 34px;
    line-height: 34px;
    width: 80px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: border-color 0.2s ease-out;
    -moz-transition: border-color 0.2s ease-out;
    -o-transition: border-color 0.2s ease-out;
    transition: border-color 0.2s ease-out;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    .step-action-buttons .add-icon:hover {
      border-color: #5c5cff;
      color: #5c5cff; }
  .step-action-buttons.editable-buttons .color-picker-button-container {
    display: flex;
    align-items: center; }
    .step-action-buttons.editable-buttons .color-picker-button-container span.editableValue {
      display: none;
      background-color: rgba(0, 0, 0, 0.3);
      font-family: monospace;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
      padding: 0 10px;
      margin-right: 4px;
      line-height: 26px;
      height: 26px;
      color: #888888; }
      .step-action-buttons.editable-buttons .color-picker-button-container span.editableValue:focus, .step-action-buttons.editable-buttons .color-picker-button-container span.editableValue:hover {
        outline: none; }
      .step-action-buttons.editable-buttons .color-picker-button-container span.editableValue.show, .step-action-buttons.editable-buttons .color-picker-button-container span.editableValue.has-focus {
        display: inline; }
  .step-action-buttons.editable-buttons .step-button {
    position: relative; }
  .step-action-buttons.editable-buttons .step-button:hover + .hover-controls, .step-action-buttons.editable-buttons .hover-controls:hover {
    display: flex;
    z-index: 2;
    right: 0;
    left: initial;
    -webkit-animation-name: fadeInFromNone;
    animation-name: fadeInFromNone;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
    .step-action-buttons.editable-buttons .step-button:hover + .hover-controls .color-picker-button, .step-action-buttons.editable-buttons .hover-controls:hover .color-picker-button {
      position: relative; }
    .step-action-buttons.editable-buttons .step-button:hover + .hover-controls .colorpicker, .step-action-buttons.editable-buttons .hover-controls:hover .colorpicker {
      margin-top: -124px; }
      .step-action-buttons.editable-buttons .step-button:hover + .hover-controls .colorpicker:before, .step-action-buttons.editable-buttons .step-button:hover + .hover-controls .colorpicker:after, .step-action-buttons.editable-buttons .hover-controls:hover .colorpicker:before, .step-action-buttons.editable-buttons .hover-controls:hover .colorpicker:after {
        display: none; }
      .step-action-buttons.editable-buttons .step-button:hover + .hover-controls .colorpicker .colorpicker-alpha, .step-action-buttons.editable-buttons .hover-controls:hover .colorpicker .colorpicker-alpha {
        display: none; }
  .step-action-buttons.editable-buttons .appcues-button {
    padding: 0;
    -webkit-user-select: initial;
    -moz-user-select: initial;
    user-select: initial; }
    .step-action-buttons.editable-buttons .appcues-button.step-button:after, .step-action-buttons.editable-buttons .appcues-button.step-button:before {
      cursor: default; }
    .step-action-buttons.editable-buttons .appcues-button [contenteditable] {
      padding: 6px 18px;
      margin: 2px; }
      .step-action-buttons.editable-buttons .appcues-button [contenteditable]:focus, .step-action-buttons.editable-buttons .appcues-button [contenteditable]:hover {
        outline: none;
        cursor: text;
        border: 1px dashed white;
        padding: 5px 17px; }
    .step-action-buttons.editable-buttons .appcues-button[data-step="prev"] {
      padding-left: 34px; }
      .step-action-buttons.editable-buttons .appcues-button[data-step="prev"] [contenteditable] {
        padding-left: 10px; }
        .step-action-buttons.editable-buttons .appcues-button[data-step="prev"] [contenteditable]:focus, .step-action-buttons.editable-buttons .appcues-button[data-step="prev"] [contenteditable]:hover {
          padding-left: 9px; }
    .step-action-buttons.editable-buttons .appcues-button[data-step="next"] {
      padding-right: 34px; }
      .step-action-buttons.editable-buttons .appcues-button[data-step="next"] [contenteditable] {
        padding-right: 10px; }
        .step-action-buttons.editable-buttons .appcues-button[data-step="next"] [contenteditable]:focus, .step-action-buttons.editable-buttons .appcues-button[data-step="next"] [contenteditable]:hover {
          padding-right: 9px; }
    .step-action-buttons.editable-buttons .appcues-button + .appcues-button {
      margin-left: 7px; }
