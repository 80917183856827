.bigbody {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f6f7fb;
  z-index: 10; }

.registration-header {
  width: 100%;
  margin-bottom: 20px;
  text-align: center; }
  .registration-header h1 {
    padding-top: 50px; }

.logo-header {
  padding: 40px 0 20px; }

.auth-page.container.row-flex.flex-wrap {
  margin-left: 0;
  margin-right: 0; }

.validation-error {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }
  .validation-error .alert {
    margin: 0;
    text-align: center; }

.registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  width: auto;
  padding: 10px;
  position: relative;
  z-index: 11; }
  .registration-container .panel {
    border-radius: 8px;
    border: none;
    padding: 15px 35px;
    box-shadow: rgba(28, 23, 23, 0.03) 0px 0px 16px; }
    .registration-container .panel button.btn-primary {
      background-color: #5c5cff;
      border-color: #5c5cff;
      border-radius: 8px; }
    .registration-container .panel .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .registration-container .panel .form-group label {
        color: #576784;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: normal; }
      .registration-container .panel .form-group input.form-control {
        border: 1px solid #c0c6d9;
        border-radius: 8px; }
      .registration-container .panel .form-group.request-demo {
        flex-direction: row;
        margin-bottom: 5px;
        padding: 0 10px; }
        .registration-container .panel .form-group.request-demo [name='request_demo'] {
          width: 32px;
          height: 30px; }
        .registration-container .panel .form-group.request-demo label {
          width: 300px;
          font-size: 14px;
          text-align: left; }
      .registration-container .panel .form-group.work-email-group:last-child {
        margin-bottom: 0; }
      .registration-container .panel .form-group .single-group input:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
  .registration-container .flex-col {
    min-width: 300px; }
  .registration-container .divider {
    font-weight: 700;
    color: #979eb4;
    margin-bottom: 20px;
    font-size: 14px;
    letter-spacing: 0.06em; }
  .registration-container .panel .divider {
    color: #bbbbbb; }
  .registration-container .form-login-container {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 6px 0 15px;
    color: #576784; }
    .registration-container .form-login-container a {
      color: #5c5cff;
      font-weight: 700; }
  .registration-container .google-auth {
    display: flex;
    color: #5c5cff;
    align-items: center;
    justify-content: center;
    padding: 11px 12px 9px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    border: none; }
    .registration-container .google-auth .g-logo {
      display: flex;
      margin-left: 15px;
      align-items: center; }
      .registration-container .google-auth .g-logo img {
        width: 24px; }
    .registration-container .google-auth span {
      flex-grow: 1;
      font-weight: 600; }
  .registration-container .panel .google-auth {
    margin: 24px; }
  .registration-container .single-group {
    display: flex;
    flex-direction: row;
    width: 100%; }
    .registration-container .single-group input {
      flex-grow: 1; }
    .registration-container .single-group button.btn:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0 20px;
      margin-left: -2px; }
  .registration-container h1 {
    color: #222a37;
    font-size: 45px;
    margin-bottom: 18px;
    font-weight: 400;
    letter-spacing: -0.02em; }
  .registration-container h2 {
    font-size: 20px;
    color: #556887;
    font-weight: 400; }
  .registration-container .form-control {
    height: 46px;
    color: #4c4c4c !important; }
  .registration-container .login-text-cta {
    font-size: 15px;
    color: #979eb4; }
  .registration-container .signup-terms {
    margin: 0 auto 25px;
    padding: 0 10px;
    color: #979eb4;
    font-size: 14px;
    cursor: default; }
    .registration-container .signup-terms a {
      font-weight: 700;
      color: #979eb4; }
      .registration-container .signup-terms a:hover {
        cursor: pointer; }
  .registration-container .btn.btn-block {
    padding: 14px 20px; }
  .registration-container .registration-marketing {
    padding: 0 24px;
    font-size: 16px; }
    .registration-container .registration-marketing h2 {
      font-weight: 600;
      font-size: 19px;
      margin-bottom: 10px; }
    .registration-container .registration-marketing ul {
      line-height: 2em;
      margin-bottom: 1.5em;
      padding-left: 0; }
      .registration-container .registration-marketing ul li {
        list-style-type: none;
        padding: 0;
        color: white;
        font-weight: 300;
        font-size: 17px; }
        .registration-container .registration-marketing ul li a {
          color: white;
          font-weight: 600; }
        .registration-container .registration-marketing ul li i.fa {
          min-width: 32px;
          font-size: 16px; }
          .registration-container .registration-marketing ul li i.fa.fa-question {
            padding-left: 4px; }
    .registration-container .registration-marketing hr {
      border-color: rgba(255, 255, 255, 0.1); }
    .registration-container .registration-marketing blockquote {
      font-weight: 400;
      font-size: 16px;
      max-width: 300px;
      line-height: 1.6em;
      font-style: italic;
      color: white;
      border: none;
      padding: 0; }
    .registration-container .registration-marketing .profile {
      color: rgba(255, 255, 255, 0.7); }
      .registration-container .registration-marketing .profile img {
        width: 32px;
        border-radius: 100px;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
        float: left;
        margin-right: 10px;
        margin-top: 5px; }
      .registration-container .registration-marketing .profile strong {
        font-weight: 600; }
      .registration-container .registration-marketing .profile em {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        display: block; }

.panel-body.register-page {
  padding: 28px;
  min-width: 300px; }

.auth-panel {
  min-width: 350px; }

.auth-form .checkbox {
  display: inline-block;
  margin: 0; }
.auth-form .checkbox + .btn-block {
  margin-top: 10px; }

.auth-banner {
  min-height: 50px;
  background-color: white;
  color: #848484;
  margin-top: 6px; }
  .auth-banner .dropdown-menu a[href] {
    color: #848484; }
  .auth-banner .dropdown-menu .divider {
    height: 2px;
    background-color: #e3e3e3; }

.auth-page .registration-body-content {
  margin: 0 auto; }
.auth-page .redirect-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 15, 30, 0.9);
  z-index: 999; }
.auth-page .redirect {
  position: absolute;
  top: 32%;
  z-index: 1000; }
  .auth-page .redirect h2 {
    color: #82d1f9;
    font-size: 22px; }
  .auth-page .redirect p:hover {
    cursor: pointer;
    color: grey; }
.auth-page .logo {
  margin-top: 20px;
  margin-bottom: 40px; }
.auth-page .well {
  color: rgba(103, 121, 126, 0.9);
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background: white;
  box-shadow: rgba(28, 23, 23, 0.03) 0px 0px 16px;
  border-radius: 8px; }
  .auth-page .well a[href] {
    color: #67797e; }
  .auth-page .well:hover {
    box-shadow: rgba(28, 23, 23, 0.03) 0px 0px 16px, 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    color: #67797e; }

@media only screen and (max-width: 1040px) {
  #-LAybF61v9hx1sSmdSru {
    display: none; } }
@media only screen and (max-width: 1040px) and (min-width: 476px) {
  .auth-panel {
    min-width: 320px; }

  .full-screen-panel {
    min-width: 475px; } }
@media only screen and (max-width: 475px) {
  .auth-panel {
    min-width: 250px; }

  .reg-panel {
    max-width: 360px;
    padding-left: 0px;
    padding-right: 0px; }

  .panel-body.register-page {
    min-width: 230px; } }
