@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-60px);
    -moz-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -o-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-60px);
    -moz-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -o-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-60px);
    -moz-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -o-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-60px);
    -moz-transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -o-transform: translateX(-60px);
    transform: translateX(-60px);
    opacity: 0; }

  50% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

.slide-in {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s; }

@-webkit-keyframes slideDownSubtle {
  0% {
    -webkit-transform: scale(0.992);
    -moz-transform: scale(0.992);
    -ms-transform: scale(0.992);
    -o-transform: scale(0.992);
    transform: scale(0.992);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes slideDownSubtle {
  0% {
    -webkit-transform: scale(0.992);
    -moz-transform: scale(0.992);
    -ms-transform: scale(0.992);
    -o-transform: scale(0.992);
    transform: scale(0.992);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes slideDownSubtle {
  0% {
    -webkit-transform: scale(0.992);
    -moz-transform: scale(0.992);
    -ms-transform: scale(0.992);
    -o-transform: scale(0.992);
    transform: scale(0.992);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes slideDownSubtle {
  0% {
    -webkit-transform: scale(0.992);
    -moz-transform: scale(0.992);
    -ms-transform: scale(0.992);
    -o-transform: scale(0.992);
    transform: scale(0.992);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes slideDownSubtleNav {
  0% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes slideDownSubtleNav {
  0% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes slideDownSubtleNav {
  0% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideDownSubtleNav {
  0% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0; }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

.slide-in-subtle {
  -webkit-animation-name: slideDownSubtle;
  animation-name: slideDownSubtle;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s; }

@-webkit-keyframes halfspin {
  to {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); } }

@-moz-keyframes halfspin {
  to {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); } }

@-ms-keyframes halfspin {
  to {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); } }

@keyframes halfspin {
  to {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); } }

.spinner {
  width: 24px;
  height: 24px;
  margin: auto;
  margin-top: 100px; }
  .spinner:before {
    display: block;
    width: 22px;
    height: 22px;
    border-top: 2px solid #bbbbbb;
    border-right: 2px solid #4c4c4c;
    border-left: 2px solid #4c4c4c;
    border-bottom: 2px solid #bbbbbb;
    content: "";
    border-radius: 100%;
    animation: halfspin 0.3s linear infinite;
    -webkit-animation: halfspin 0.3s linear infinite;
    -moz-animation: halfspin 0.3s linear infinite; }

@-webkit-keyframes rotationalLoop {
  from {
    transform: rotate(0deg) translate(-2px) rotate(0deg); }

  to {
    transform: rotate(360deg) translate(-2px) rotate(-360deg); } }

@-moz-keyframes rotationalLoop {
  from {
    transform: rotate(0deg) translate(-2px) rotate(0deg); }

  to {
    transform: rotate(360deg) translate(-2px) rotate(-360deg); } }

@-ms-keyframes rotationalLoop {
  from {
    transform: rotate(0deg) translate(-2px) rotate(0deg); }

  to {
    transform: rotate(360deg) translate(-2px) rotate(-360deg); } }

@keyframes rotationalLoop {
  from {
    transform: rotate(0deg) translate(-2px) rotate(0deg); }

  to {
    transform: rotate(360deg) translate(-2px) rotate(-360deg); } }
