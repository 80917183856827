.styling-container .row::before, .styling-container .row::after {
  display: none; }
.styling-container .new-theme-section .form-group {
  display: flex;
  justify-content: space-between; }
.styling-container .new-theme-section input.form-control {
  flex-basis: 100%;
  margin-right: 8px; }
.styling-container .panel {
  border: none; }
  .styling-container .panel .panel-heading {
    background: none;
    border: none;
    padding: 8px 0; }
    .styling-container .panel .panel-heading .panel-title-row {
      display: flex;
      justify-content: space-between;
      margin: 0 15px; }
      .styling-container .panel .panel-heading .panel-title-row .panel-title {
        flex-basis: 90%; }
    .styling-container .panel .panel-heading .panel-title {
      font-weight: 600;
      font-size: 24px; }
      .styling-container .panel .panel-heading .panel-title.divider-title {
        font-family: "Muli", "Avenir", "Futura", "Avant Garde", "Century Gothic", Helvetica, Arial, sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #848484;
        letter-spacing: 0.05em;
        text-transform: uppercase; }
  .styling-container .panel .panel-body {
    padding: 0; }
.styling-container .theme-id-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.7em; }
  .styling-container .theme-id-section strong {
    margin-right: 5px; }
  .styling-container .theme-id-section .theme-id {
    border-radius: 5px;
    opacity: 0.7;
    background-color: #e3e3e3;
    font-family: Monospace;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center; }

.styling-panel {
  border: none; }
  .styling-panel .styling-panel-body {
    padding: 0; }
  .styling-panel .nav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px; }
    .styling-panel .nav li {
      flex-basis: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: relative; }
      .styling-panel .nav li a {
        color: #848484; }
        .styling-panel .nav li a:hover {
          color: #5c5cff; }
    .styling-panel .nav li.active a, .styling-panel .nav li.active a:hover {
      text-decoration: none;
      color: #5c5cff; }
    .styling-panel .nav li.active::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #5c5cff;
      height: 4px;
      border-radius: 4px; }
    .styling-panel .nav::before, .styling-panel .nav::after {
      display: none; }
  .styling-panel .nav-tabs {
    margin-bottom: 15px; }
  .styling-panel .tab-pane {
    margin-top: 8px;
    position: relative; }
    .styling-panel .tab-pane .expand {
      position: absolute;
      right: 0;
      top: 10px; }
  .styling-panel .ace-editor {
    height: 600px;
    width: 100%;
    margin-bottom: 15px; }
  .styling-panel .divider {
    position: relative;
    margin-bottom: 12px;
    margin-top: 8px; }
    .styling-panel .divider:first-child {
      margin-top: 0; }
    .styling-panel .divider span {
      background-color: white;
      display: inline-block;
      position: relative;
      margin-bottom: 4px;
      font-family: "Muli", "Avenir", "Futura", "Avant Garde", "Century Gothic", Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      color: #848484;
      letter-spacing: 0.06em; }
  .styling-panel label .help-text {
    margin-left: 10px; }
  .styling-panel .help-block {
    font-style: italic;
    font-size: 13px; }

.unsaved-changes {
  position: relative;
  padding-right: 30px; }

.styling-preview-panel {
  background: #f4f4f4;
  height: 1300px; }
  .styling-preview-panel a {
    color: #bbbbbb; }
    .styling-preview-panel a:hover {
      color: #4c4c4c; }
  .styling-preview-panel .styling-preview-frame {
    height: 550px;
    width: 100%; }
    .styling-preview-panel .styling-preview-frame.styling-preview-frame-hotspots {
      height: 200px; }

.flex-between {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 36px;
  justify-content: space-between;
  margin-right: -20px; }
  .flex-between .select-template-button-option {
    display: flex;
    flex: 1;
    max-width: 48%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 2px solid #e3e3e3;
    margin-right: 20px;
    padding: 10px 30px 10px 5px;
    cursor: pointer; }
    .flex-between .select-template-button-option:hover {
      border-color: #00b2e5; }
      .flex-between .select-template-button-option:hover:active, .flex-between .select-template-button-option:hover:focus {
        border-color: #006d8c; }
    .flex-between .select-template-button-option .option-section-left {
      margin: 0 25px;
      font-size: 40px;
      color: #e3e3e3; }
    .flex-between .select-template-button-option .option-section-right {
      text-align: left; }
    .flex-between .select-template-button-option h3 {
      font-size: 17px;
      font-weight: 600;
      color: #4c4c4c;
      margin-top: 1em; }
    .flex-between .select-template-button-option p {
      color: #848484;
      font-size: 15px; }
  .flex-between .optionSelected {
    border-color: #00b2e5;
    background-color: #00b2e5;
    color: white; }
    .flex-between .optionSelected p, .flex-between .optionSelected h3 {
      color: white; }

.radio-button-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .radio-button-form input.radio-button {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden; }
  .radio-button-form label.radio-button-selector {
    padding: 10px;
    border: 2px solid #f4f4f4;
    border-radius: 4px;
    color: inherit;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    flex-basis: 50%;
    transition: border 0.3s ease, background 0.3s ease, color 0.3s ease; }
    .radio-button-form label.radio-button-selector:hover, .radio-button-form label.radio-button-selector span:hover, .radio-button-form label.radio-button-selector img:hover {
      cursor: pointer; }
  .radio-button-form input.radio-button:checked + label.radio-button-selector {
    border: 2px solid #00b2e5;
    background: #00b2e5;
    color: white; }
  .radio-button-form img.radio-button-image {
    width: 70px;
    margin-bottom: 8px;
    height: auto; }

.click-through-container {
  font-style: italic; }
