.preview-steps {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; }
  .preview-steps .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    border: none;
    background-color: #222222;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    height: 42px;
    z-index: 10005;
    color: #e3e3e3;
    line-height: 42px; }
  .preview-steps .navbar-btn-block {
    height: 42px;
    padding: 0 15px; }
  .preview-steps .btn-primary {
    cursor: pointer; }
  .preview-steps .navbar-nav, .preview-steps .navbar-nav > li {
    height: 42px; }
  .preview-steps .navbar-nav > li {
    border-left: 1px solid #323232;
    border-right: 1px solid black; }
    .preview-steps .navbar-nav > li:first-child {
      border-left: none; }
    .preview-steps .navbar-nav > li:last-child {
      border-right: none; }
    .preview-steps .navbar-nav > li > a, .preview-steps .navbar-nav > li > span {
      line-height: 42px;
      color: inherit;
      text-shadow: none;
      margin: 0;
      padding: 0 10px;
      display: block; }
    .preview-steps .navbar-nav > li > a {
      -webkit-transition: all 300ms ease-out;
      -moz-transition: all 300ms ease-out;
      -o-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      -webkit-transition-property: color;
      -moz-transition-property: color;
      -o-transition-property: color;
      transition-property: color; }
      .preview-steps .navbar-nav > li > a:hover {
        color: white;
        background-color: initial; }
    .preview-steps .navbar-nav > li .checkbox {
      margin: 0; }
      .preview-steps .navbar-nav > li .checkbox input[type='checkbox'] {
        margin-top: 14px; }
  .preview-steps .preview-form input {
    margin: 0 0 0 10px;
    padding: 5px 8px;
    line-height: 1.2em;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #1e1e1e;
    background-color: #e3e3e3; }
  .preview-steps .preview-form button {
    padding: 5px 8px;
    margin-bottom: 3px;
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  @media (min-width: 1082px) {
    .preview-steps .preview-form input {
      width: 190px; } }
  @media (max-width: 992px) {
    .preview-steps .preview-form {
      display: none !important;
      visibility: hidden !important; } }
  .preview-steps > iframe {
    padding-top: 42px;
    border: none; }

.preview-steps .navbar-nav > li .pattern-icon-container {
  padding-top: 9px;
  padding-bottom: 9px; }

.pattern-icon {
  width: 32px;
  height: 24px;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: border-color;
  -moz-transition-property: border-color;
  -o-transition-property: border-color;
  transition-property: border-color; }
  .pattern-icon:hover .pattern-inside, li.active .pattern-icon .pattern-inside {
    background-color: white; }
  .pattern-icon .pattern-inside {
    width: 100%;
    height: 100%;
    background-color: #979797;
    background-clip: content-box;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    -o-transition-property: background-color;
    transition-property: background-color; }
  .pattern-icon.pattern-modal {
    border: 1px solid #979797; }
    .pattern-icon.pattern-modal:hover, li.active .pattern-icon.pattern-modal {
      border-color: white; }
    .pattern-icon.pattern-modal .pattern-inside {
      padding: 20%; }
  .pattern-icon.pattern-left {
    border: 1px solid #979797; }
    .pattern-icon.pattern-left:hover, li.active .pattern-icon.pattern-left {
      border-color: white; }
    .pattern-icon.pattern-left .pattern-inside {
      padding-right: 25%; }

@-webkit-keyframes blastOff {
  0%, 10%, 20% {
    opacity: 1;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }

  5%, 15% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }

  30% {
    -webkit-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -moz-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -ms-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -o-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg); }

  50% {
    opacity: 1;
    -webkit-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -moz-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -ms-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -o-transform: translateX(300%) translateY(-300%) skewX(10deg);
    transform: translateX(300%) translateY(-300%) skewX(10deg); }

  51% {
    opacity: 0; }

  52% {
    -webkit-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -moz-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -ms-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -o-transform: translateX(-300%) translateY(300%) skewX(10deg);
    transform: translateX(-300%) translateY(300%) skewX(10deg); }

  53% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0) translateY(0) skewX(0deg);
    -moz-transform: translateX(0) translateY(0) skewX(0deg);
    -ms-transform: translateX(0) translateY(0) skewX(0deg);
    -o-transform: translateX(0) translateY(0) skewX(0deg);
    transform: translateX(0) translateY(0) skewX(0deg); } }

@-moz-keyframes blastOff {
  0%, 10%, 20% {
    opacity: 1;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }

  5%, 15% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }

  30% {
    -webkit-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -moz-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -ms-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -o-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg); }

  50% {
    opacity: 1;
    -webkit-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -moz-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -ms-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -o-transform: translateX(300%) translateY(-300%) skewX(10deg);
    transform: translateX(300%) translateY(-300%) skewX(10deg); }

  51% {
    opacity: 0; }

  52% {
    -webkit-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -moz-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -ms-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -o-transform: translateX(-300%) translateY(300%) skewX(10deg);
    transform: translateX(-300%) translateY(300%) skewX(10deg); }

  53% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0) translateY(0) skewX(0deg);
    -moz-transform: translateX(0) translateY(0) skewX(0deg);
    -ms-transform: translateX(0) translateY(0) skewX(0deg);
    -o-transform: translateX(0) translateY(0) skewX(0deg);
    transform: translateX(0) translateY(0) skewX(0deg); } }

@-ms-keyframes blastOff {
  0%, 10%, 20% {
    opacity: 1;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }

  5%, 15% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }

  30% {
    -webkit-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -moz-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -ms-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -o-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg); }

  50% {
    opacity: 1;
    -webkit-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -moz-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -ms-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -o-transform: translateX(300%) translateY(-300%) skewX(10deg);
    transform: translateX(300%) translateY(-300%) skewX(10deg); }

  51% {
    opacity: 0; }

  52% {
    -webkit-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -moz-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -ms-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -o-transform: translateX(-300%) translateY(300%) skewX(10deg);
    transform: translateX(-300%) translateY(300%) skewX(10deg); }

  53% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0) translateY(0) skewX(0deg);
    -moz-transform: translateX(0) translateY(0) skewX(0deg);
    -ms-transform: translateX(0) translateY(0) skewX(0deg);
    -o-transform: translateX(0) translateY(0) skewX(0deg);
    transform: translateX(0) translateY(0) skewX(0deg); } }

@keyframes blastOff {
  0%, 10%, 20% {
    opacity: 1;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }

  5%, 15% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }

  30% {
    -webkit-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -moz-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -ms-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    -o-transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg);
    transform: translateX(-25%) translateY(25%) skewX(-10deg) rotate(0deg); }

  50% {
    opacity: 1;
    -webkit-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -moz-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -ms-transform: translateX(300%) translateY(-300%) skewX(10deg);
    -o-transform: translateX(300%) translateY(-300%) skewX(10deg);
    transform: translateX(300%) translateY(-300%) skewX(10deg); }

  51% {
    opacity: 0; }

  52% {
    -webkit-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -moz-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -ms-transform: translateX(-300%) translateY(300%) skewX(10deg);
    -o-transform: translateX(-300%) translateY(300%) skewX(10deg);
    transform: translateX(-300%) translateY(300%) skewX(10deg); }

  53% {
    opacity: 1; }

  100% {
    -webkit-transform: translateX(0) translateY(0) skewX(0deg);
    -moz-transform: translateX(0) translateY(0) skewX(0deg);
    -ms-transform: translateX(0) translateY(0) skewX(0deg);
    -o-transform: translateX(0) translateY(0) skewX(0deg);
    transform: translateX(0) translateY(0) skewX(0deg); } }
