.navigation-bar {
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  padding: 5px 4px;
  position: relative; }
  .navigation-bar input {
    padding-left: 2.5em; }
  .navigation-bar .inset-label {
    position: absolute;
    left: 10px;
    width: 1em;
    line-height: 34px;
    z-index: 2; }
