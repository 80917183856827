.account-users-list {
  padding: 0; }
  .account-users-list .table-cell {
    padding: 10px;
    vertical-align: middle; }
  .account-users-list .media .media-left {
    padding-right: 20px; }
  .account-users-list img.avatar {
    height: auto;
    width: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
  .account-users-list .loading-state {
    color: #e3e3e3;
    padding: 30px;
    text-align: center; }
  .account-users-list .display-table.highlight {
    background-color: #ffe7e7;
    position: relative; }
    .account-users-list .display-table.highlight:before {
      background-color: rgba(255, 255, 255, 0.5);
      color: #5c5cff;
      content: "Click to edit your profile";
      cursor: pointer;
      padding-top: 25px;
      position: absolute;
      text-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-out;
      -moz-transition: opacity 0.2s ease-out;
      -o-transition: opacity 0.2s ease-out;
      transition: opacity 0.2s ease-out; }
    .account-users-list .display-table.highlight:hover:before {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }
