.appcues-button[data-step='prev']:before, .appcues-button[data-step='next']:after {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 8px 10px;
  line-height: 20px; }

.appcues-button {
  background-color: #cccccc;
  cursor: pointer;
  padding: 8px 18px;
  color: white;
  font-weight: bold;
  display: inline-block;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out, color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out, color 0.2s ease-out;
  transition: background-color 0.2s ease-out, color 0.2s ease-out; }
  .appcues-button:hover {
    text-decoration: none;
    color: black;
    background-color: #dedede; }
  .appcues-button.appcues-button-success {
    background-color: #5cb85c; }
    .appcues-button.appcues-button-success:hover {
      background-color: #75c375; }
    .appcues-button.appcues-button-success.disabled {
      background-color: #eaf6ea; }
    .appcues-button.appcues-button-success [contenteditable]::-moz-selection {
      background: rgba(255, 255, 255, 0.6); }
    .appcues-button.appcues-button-success [contenteditable]::selection {
      background: rgba(255, 255, 255, 0.6); }
  .appcues-button[data-step='prev'] {
    padding-left: 45px; }
    .appcues-button[data-step='prev']:before {
      left: 0;
      content: "\25C0";
      border-right: 1px solid white; }
  .appcues-button[data-step='next'] {
    padding-right: 45px; }
    .appcues-button[data-step='next']:after {
      right: 0;
      content: "\25B6";
      border-left: 1px solid white; }
