.sub-header {
  display: inline-flex;
  margin: 25px;
  color: white;
  font-size: 18px; }

.ideas-inspiration-content {
  width: 100%;
  margin: 0;
  padding: 20px;
  background-color: white; }
  .ideas-inspiration-content .article-content {
    display: block;
    width: 900px;
    margin: 30px auto; }
    .ideas-inspiration-content .article-content h3 {
      margin-bottom: 16px; }
    .ideas-inspiration-content .article-content p {
      max-width: 500px;
      margin: 10px auto 3px; }
    .ideas-inspiration-content .article-content .slideshow .button-container {
      display: inline-flex;
      margin-top: 20px; }
      .ideas-inspiration-content .article-content .slideshow .button-container.left-button {
        float: left;
        margin-left: 25px; }
      .ideas-inspiration-content .article-content .slideshow .button-container.right-button {
        float: right;
        margin-right: 25px; }
      .ideas-inspiration-content .article-content .slideshow .button-container button {
        height: 350px;
        width: 35px;
        background-color: transparent;
        border: transparent;
        color: grey;
        font-size: 26px; }
        .ideas-inspiration-content .article-content .slideshow .button-container button:hover {
          background-color: rgba(184, 184, 184, 0.35);
          cursor: pointer; }
        .ideas-inspiration-content .article-content .slideshow .button-container button:focus {
          outline: grey; }
    .ideas-inspiration-content .article-content .slideshow .img-container {
      display: inline-flex;
      padding: 16px 10px;
      max-width: 85%;
      overflow: hidden; }
      .ideas-inspiration-content .article-content .slideshow .img-container img {
        height: 400px;
        box-shadow: 2px 3px 8px 2px rgba(0, 0, 0, 0.3); }

.cta-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 55px; }
  .cta-button-container .apc-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 40px;
    padding: 0 20px;
    margin: 0 10px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer; }
    .cta-button-container .apc-button.quickstart-menu {
      width: 227px;
      border: transparent;
      background-color: #009ceb;
      color: white; }
      .cta-button-container .apc-button.quickstart-menu:hover {
        background-color: #007fbf; }
    .cta-button-container .apc-button:focus {
      outline: #007fbf; }
