#apc-react-root {
  min-height: 100vh; }

.apc-react-body .breadcrumbs.root {
  position: relative !important;
  top: unset !important;
  max-width: none !important; }

.checklists-edit .apc-react-body .breadcrumbs.root {
  padding: 0; }

.events-attributes > #apc-react-root > .apc-react-body > div > div > div > h2 {
  padding: 0 !important;
  margin: 0 !important; }

.isIFramed .container.styling-container {
  padding-top: 28px; }
.isIFramed .row {
  margin-left: 0;
  margin-right: 0; }
