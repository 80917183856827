.extend-trial-container {
  text-align: center; }
  .extend-trial-container .extend-trial-message {
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px; }
    .extend-trial-container .extend-trial-message .small-text {
      font-size: 14px;
      color: #848484; }
