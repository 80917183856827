* {
  box-sizing: border-box; }

input:focus, input[type=text]:focus {
  outline: none; }

.hidden {
  display: none; }

.standaloneContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .standaloneContainer:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 22;
    transition: opacity 0.4s ease;
    pointer-events: none;
    opacity: 0; }
  .standaloneContainer.blocked:after {
    pointer-events: auto;
    opacity: 1; }
  .standaloneContainer.frameBlurred .previewFrame {
    filter: blur(8px); }
  .standaloneContainer.frameBlurred .previewFrameOverlay:before {
    opacity: 1; }
  .standaloneContainer a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none; }
    .standaloneContainer a:hover {
      color: rgba(255, 255, 255, 0.8); }
  .standaloneContainer .previewFrameOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    font-family: Open Sans, Lucida Grande, Segoe UI, Calibri, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    z-index: 2; }
    .standaloneContainer .previewFrameOverlay:before {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      transition: opacity ease-in 0.4s;
      z-index: -1; }
    .standaloneContainer .previewFrameOverlay .templateSelectionContainer {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 10px 0 13px; }
      .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 366px;
        padding: 28px;
        margin: 5px 10px;
        overflow: hidden;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.8);
        transition: background ease-in 0.1s;
        cursor: pointer; }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton:not(.mutedSelectTemplateButton):hover {
          background: rgba(255, 255, 255, 0.9); }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton {
          background: rgba(255, 255, 255, 0.3);
          cursor: default; }
          .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton * {
            cursor: default; }
          .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton img {
            filter: opacity(50%); }
          .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton h1, .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton h2 {
            opacity: 0.7; }
          .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton:hover {
            background: rgba(255, 255, 255, 0.5); }
            .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton.mutedSelectTemplateButton:hover .selectTemplateButtonContentContainer {
              transform: translateY(-10px); }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton * {
          cursor: pointer; }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton h2 {
          font-size: 18px;
          font-weight: 600;
          color: #4e4d4c;
          margin-bottom: 6px; }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton .templateDescription {
          color: #7f7d7c;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          line-height: 19px; }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton .templateImageContainer {
          display: flex;
          height: 79px;
          align-items: center;
          justify-content: center;
          margin-bottom: 18px; }
        .standaloneContainer .previewFrameOverlay .templateSelectionContainer .selectTemplateButton .selectTemplateButtonContentContainer {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: center;
          align-items: center;
          transform: translateY(0);
          transition: transform ease-in 0.2s; }
    .standaloneContainer .previewFrameOverlay .modal.domainModal {
      width: 480px; }
    .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 450px;
      margin: 5px 0; }
      .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer .colorContainer {
        display: flex;
        margin: 5px;
        border-radius: 4px;
        border: 2px solid transparent;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity ease-in 0.1s; }
        .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer .colorContainer:hover, .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer .colorContainer:active, .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer .colorContainer:focus {
          opacity: 1; }
        .standaloneContainer .previewFrameOverlay .modal.colorModal .colorSwatchContainer .colorContainer .innerColor {
          display: flex;
          width: 75px;
          height: 50px;
          border-radius: 4px;
          border: 2px solid white;
          cursor: pointer; }
  .standaloneContainer .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    max-width: 100%;
    border-radius: 8px;
    padding: 30px 40px 25px;
    color: white;
    font-size: 16px;
    font-family: "Source Sans Pro", "Lucida Grande", "Segoe UI", "Calibri", Helvetica, Arial, sans-serif;
    overflow: auto; }
    .standaloneContainer .modal.centered h2 {
      text-align: center; }
    .standaloneContainer .modal h1 {
      font-weight: 200;
      font-size: 36px;
      font-family: "Source Sans Pro", "Lucida Grande", "Segoe UI", "Calibri", Helvetica, Arial, sans-serif;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.3) 0 0 40px;
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      line-height: 43px; }
    .standaloneContainer .modal h2 {
      font-weight: 400;
      font-size: 16px;
      font-family: "Source Sans Pro", "Lucida Grande", "Segoe UI", "Calibri", Helvetica, Arial, sans-serif;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.2) 0 0 40px;
      max-width: 595px;
      width: 100%;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 22px; }
    .standaloneContainer .modal input {
      display: flex;
      padding: 10px;
      margin-top: 5px;
      border-radius: 2px;
      border: 1px solid #dddddd;
      line-height: 24px;
      font-size: 17px;
      color: #666666;
      text-align: center; }
    .standaloneContainer .modal btn span {
      font-weight: 500;
      font-size: 18px; }
    .standaloneContainer .modal .form {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 5px; }
    .standaloneContainer .modal .inputButtonGroup input {
      margin: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: none;
      flex-grow: 1; }
    .standaloneContainer .modal .inputButtonGroup button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      text-align: center;
      cursor: pointer;
      color: white;
      font-size: 18px;
      font-weight: 400; }
    .standaloneContainer .modal .inputButtonGroup .success {
      background-color: #277ca0;
      border-color: transparent; }
    .standaloneContainer .modal .inputButtonGroup .btn {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 10px 25px; }
  .standaloneContainer .myAppcuesFrame {
    display: none; }
  .standaloneContainer .previewFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    transition: filter ease-in 0.2s; }
    .standaloneContainer .previewFrame:not(.loaded) {
      pointer-events: none; }
      .standaloneContainer .previewFrame:not(.loaded) * {
        pointer-events: none; }
  .standaloneContainer .liveFooter {
    padding-top: 55px; }
