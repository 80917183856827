.category-container {
  margin: 0px; }

.category-button {
  width: 100%;
  height: 30px;
  margin: 5px 0px;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: background-color, border-radius;
  -moz-transition-property: background-color, border-radius;
  -o-transition-property: background-color, border-radius;
  transition-property: background-color, border-radius;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms; }
  .category-button.category-selected {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px; }
  .category-button:first-child {
    margin-top: 0px; }
  .category-button:last-child {
    margin-bottom: 0px; }
  .category-button.category-alpha {
    background-color: #fcaf79; }
    .category-button.category-alpha.category-selected, .category-button.category-alpha:hover {
      background-color: #f97416; }
  .category-button.category-beta {
    background-color: #ffe165; }
    .category-button.category-beta.category-selected, .category-button.category-beta:hover {
      background-color: #fecc00; }
  .category-button.category-gamma {
    background-color: #4dfab2; }
    .category-button.category-gamma.category-selected, .category-button.category-gamma:hover {
      background-color: #06db82; }
  .category-button.category-delta {
    background-color: #4cd7ff; }
    .category-button.category-delta.category-selected, .category-button.category-delta:hover {
      background-color: #00b2e5; }
  .category-button.category-epsilon {
    background-color: #cdb4ef; }
    .category-button.category-epsilon.category-selected, .category-button.category-epsilon:hover {
      background-color: #9460dd; }
