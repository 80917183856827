.navbar-brand {
  padding: 0;
  height: 50px; }
  .navbar-brand a {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    height: 50px;
    padding: 0 15px 0 1px; }
    .navbar-brand a > *, .navbar-brand a img.logo {
      flex: 1;
      display: inline-block;
      vertical-align: middle; }
    .navbar-brand a .push {
      height: 50px; }
    .navbar-brand a.back {
      height: 36px;
      width: 36px;
      padding: 0;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
  .navbar-brand .nav-header, .navbar-brand .nav-title {
    display: inline-block; }
  .navbar-brand .nav-header {
    margin: 0;
      margin-left: 15px; }
    .navbar-brand .nav-header .nav-title {
      margin: 0;
      padding: 0;
      font-size: 100%; }

body.is-spoofed {
  box-shadow: 0 -2px 0 4px #f97416; }

.navbar-nav .navbar-form.spoof {
  margin: 0;
  top: 10px;
  position: relative;
  height: auto; }
  .navbar-nav .navbar-form.spoof .btn {
    font-style: italic;
    height: 23px;
    padding: 0 5px;
    outline: none;
    background-color: #848484;
    border: none; }
  .navbar-nav .navbar-form.spoof input.form-control.input-sm {
    padding: 2px 8px;
    height: auto;
    background-color: #e3e3e3 !important;
    color: #4c4c4c !important;
    border: none !important;
    outline: none; }
    .navbar-nav .navbar-form.spoof input.form-control.input-sm::placeholder {
      color: rgba(0, 0, 0, 0.25); }
.navbar-nav .dropdown a, .navbar-nav .dropdown.open > a, .navbar-nav .dropdown.open > a:hover {
  border: none; }
.navbar-nav .label {
  padding: 0.35em 0.8em 0.45em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  text-shadow: none;
  color: rgba(255, 255, 255, 0.75); }
  .navbar-nav .label a, .navbar-nav .label:hover, .navbar-nav .label:hover a {
    color: white; }
.navbar-nav > li {
  display: flex;
  line-height: 50px;
  align-items: center; }
  .navbar-nav > li.separator {
    width: 2px;
    margin: 20px 0px 0 20px;
    background-color: #e3e3e3;
    border-radius: 2px;
    height: 14px; }
  .navbar-nav > li .dropdown-menu {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: -0.25rem; }
    .navbar-nav > li .dropdown-menu i {
      margin-right: 10px; }
  .navbar-nav > li p.navbar-text > a > small {
    vertical-align: middle; }
  .navbar-nav > li p.navbar-text .navbar-flows-shown-module {
    background-color: #f4f4f4;
    padding: 0px 0px 4px 10px;
    border-radius: 3px; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown-module .label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .navbar-nav > li p.navbar-text .navbar-flows-shown {
    font-size: 12px;
    margin-right: 10px; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown:hover {
      color: #4c4c4c; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown strong {
      font-weight: 500; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown .divide {
      margin-left: 1px;
      margin-right: 1px; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown .muted {
      opacity: 0.8; }
  .navbar-nav > li p.navbar-text .navbar-flows-shown-beacon .navbar-flows-shown-tooltip {
    z-index: 1000;
    display: none;
    line-height: 20px;
    font-size: 14px;
    position: absolute;
    margin-top: 25px;
    top: 18px;
    color: black;
    width: 250px;
    left: -12px;
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    border: 0;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2); }
    .navbar-nav > li p.navbar-text .navbar-flows-shown-beacon .navbar-flows-shown-tooltip .flows-shown-header {
      font-weight: 700;
      display: block;
      margin-bottom: 10px; }
    .navbar-nav > li p.navbar-text .navbar-flows-shown-beacon .navbar-flows-shown-tooltip .flows-shown-section {
      margin-bottom: 5px;
      display: block; }
  .navbar-nav > li p.navbar-text .navbar-flows-shown-beacon:hover .navbar-flows-shown-tooltip {
    display: block; }
  .navbar-nav > li > p.navbar-text {
    font-family: "Muli", "Source Sans Pro", "Lucida Grande", "Segoe UI", "Calibri", Helvetica, Arial, sans-serif;
    line-height: 49px;
    padding: 0;
    margin: 0 10px; }
  .navbar-nav > li a {
    color: #848484;
    transition: color 0.15s ease; }
    .navbar-nav > li a:hover {
      color: #5c5cff;
      text-decoration: none; }
  .navbar-nav > li > a {
    padding: 0.75em 0;
    margin: 0 0 0 20px;
    font-family: "Muli", "Source Sans Pro", "Lucida Grande", "Segoe UI", "Calibri", Helvetica, Arial, sans-serif;
    height: 50px;
    font-size: 20px;
    line-height: 1em;
    font-weight: 400;
    position: relative;
    user-select: none; }
    .navbar-nav > li > a.secondary {
      padding: 1.05em 0;
      font-size: 17px; }
    .navbar-nav > li > a#taco .appcues-widget-icon {
      font-size: 21px; }
    .navbar-nav > li > a:before {
      content: "";
      width: 100%;
      border-radius: 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
    .navbar-nav > li > a.dropdown-toggle {
      margin-right: 0px !important; }
      .navbar-nav > li > a.dropdown-toggle:hover {
        text-decoration: none; }
      .navbar-nav > li > a.dropdown-toggle:active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
      .navbar-nav > li > a.dropdown-toggle i.fa {
        padding-right: 0;
        margin-right: 6px; }
    .navbar-nav > li > a.with-img {
      padding: 12px 0; }
      .navbar-nav > li > a.with-img .dropdown-text {
        padding: 0 10px; }
      .navbar-nav > li > a.with-img img {
        margin: 0;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px; }
        .navbar-nav > li > a.with-img img + .caret {
          margin-left: 8px; }
    .navbar-nav > li > a i.fa {
      margin-top: 1px;
      vertical-align: middle; }
    .navbar-nav > li > a img.avatar {
      margin-left: 8px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      -ms-border-radius: 40px;
      -o-border-radius: 40px;
      border-radius: 40px;
      transition: box-shadow 0.2s ease; }
    .navbar-nav > li > a:hover {
      text-decoration: none; }
      .navbar-nav > li > a:hover img.avatar {
        margin-left: 8px;
        box-shadow: 0 0 0 2px white, 0 0 0 4px #5c5cff !important; }
    @media (max-width: 992px) {
      .navbar-nav > li > a {
        font-size: 17px;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 18px; }
        .navbar-nav > li > a.secondary {
          padding: 0.95em 0;
          font-size: 16px; } }
    @media (max-width: 768px) {
      .navbar-nav > li > a {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 12px; }
        .navbar-nav > li > a.secondary {
          padding: 0.85em 0;
          font-size: 15px; } }
  .navbar-nav > li.active > a {
    color: #5c5cff; }
    .navbar-nav > li.active > a:before {
      content: "";
      height: 4px;
      bottom: 0;
      background-color: #5c5cff;
      -webkit-animation: slideDownSubtleNav 0.15s ease-out;
      animation: slideDownSubtleNav 0.15s ease-out; }
.navbar-nav.navbar-right > li > a {
  margin-left: 20px; }

.navbar-nav {
  margin: inherit !important;
  float: left !important; }
  .navbar-nav.navbar-right {
    float: right !important; }
  .navbar-nav > li {
    float: left !important; }
  .navbar-nav .label {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transition-property: background-color;
    -moz-transition-property: background-color;
    -o-transition-property: background-color;
    transition-property: background-color; }
    .navbar-nav .label:hover.label-primary {
      background-color: #2929ff; }
    .navbar-nav .label:hover.label-success {
      background-color: #05a965; }
    .navbar-nav .label:hover.label-info {
      background-color: #008ab2; }
    .navbar-nav .label:hover.label-warning {
      background-color: #cba300; }
    .navbar-nav .label:hover.label-danger {
      background-color: #d65c06; }
    .navbar-nav .label:hover a {
      text-decoration: none; }

.navbar-header {
  float: left !important; }
  .navbar-header .nav-title {
    min-width: 50px; }

.new-tooltip {
  display: block;
  color: white;
  font-size: 9px;
  cursor: default;
  margin-left: 8px;
  position: relative;
  bottom: 5px;
  line-height: 1em;
  padding: 4px 6px;
  border-radius: 6px 6px 6px 0px;
  background-color: #2cb4ff;
  letter-spacing: 1.5px;
  font-weight: 900; }

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    left: -160px;
    margin-top: -3px;
    background-color: white;
    border: 0;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2); }
    .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px; }
    .navbar-nav .open .dropdown-menu > li > a {
      line-height: 22px; }
      .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
        background-image: none; } }
