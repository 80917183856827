.coachmarks-summary .thumbnails-list {
  margin: 0 1rem; }
  .coachmarks-summary .thumbnails-list .thumbnail-item {
    background-color: #f7f7f7;
    border-color: #f4f4f4;
    color: #e3e3e3;
    margin: 0.5rem 1rem 0.5rem 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .coachmarks-summary .thumbnails-list .thumbnail-item.selected {
      background-color: #8f8fff;
      color: white;
      font-weight: bold; }
      .coachmarks-summary .thumbnails-list .thumbnail-item.selected.editable {
        border-right-width: 26px !important; }
      .coachmarks-summary .thumbnails-list .thumbnail-item.selected.placeCoachmark {
        border-right-width: 2px !important; }
    .coachmarks-summary .thumbnails-list .thumbnail-item.nodeMissing {
      background-color: #fa9248;
      border-color: #f97416;
      color: white; }
      .coachmarks-summary .thumbnails-list .thumbnail-item.nodeMissing:hover {
        border-color: #f97416; }
    .coachmarks-summary .thumbnails-list .thumbnail-item span.thumb {
      display: inline-block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center; }
    .coachmarks-summary .thumbnails-list .thumbnail-item .thumbnail-controls {
      font-size: small; }
