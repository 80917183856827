.preview-frame {
  border: none;
  border-top: 1px solid #bbbbbb; }

.appcues-preview-frame-message-container {
  position: absolute;
  left: 50%;
  top: 320px; }
  .appcues-preview-frame-message-container .appcues-preview-frame-message {
    position: relative;
    left: -50%;
    width: 700px;
    -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.8);
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background-color: #eeeeee;
    padding: 0 30px 30px 30px; }
    .appcues-preview-frame-message-container .appcues-preview-frame-message .img-container {
      text-align: center; }

.powered-by-container {
  position: fixed;
  bottom: 16px;
  left: 16px;
  padding: 1px 6px;
  border-radius: 3px;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  color: #858585;
  background-color: white;
  display: table;
  opacity: 0.8;
  z-index: 9999999;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); }
  .powered-by-container svg {
    height: 1.4em; }
  .powered-by-container a {
    text-decoration: none; }
  .powered-by-container .powered-by-content {
    display: table-row;
    transition: opacity 0.3s ease;
    padding-left: 2px; }
    .powered-by-container .powered-by-content .logo-container, .powered-by-container .powered-by-content .powered-by-text {
      display: table-cell;
      vertical-align: middle;
      text-align: center; }
    .powered-by-container .powered-by-content .logo-container {
      padding-right: 0.2em;
      padding-left: 0.2em;
      padding-top: 0.4em; }
    .powered-by-container .powered-by-content .poweredby-text-top {
      font-size: 6px;
      padding: 8px 0px 0px 3px;
      line-height: 0.5;
      text-transform: uppercase; }
    .powered-by-container .powered-by-content .poweredby-text-bottom {
      padding: 0px 3px 3px 3px;
      font-size: 12px; }
    .powered-by-container .powered-by-content .powered-by-text-logo {
      font-size: 1.1em; }
  .powered-by-container .subscription-info {
    display: block;
    position: absolute;
    font-size: 10px;
    line-height: 1.3;
    left: 0;
    opacity: 0;
    font-family: Helvetica Neue, Helvetica, sans-serif;
    font-color: #4baad4;
    text-align: center;
    background-color: rgba(195, 195, 195, 0.1);
    padding: 2.7px 0px 0px 0px;
    transition: opacity 0.3s ease; }
  .powered-by-container:hover .powered-by-content {
    opacity: 0; }
  .powered-by-container:hover .subscription-info {
    opacity: 1; }
