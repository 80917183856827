.rules {
  margin-right: 3rem;
  position: relative; }
  .rules .rule-summary {
    font-size: 16px;
    margin: 15px;
    text-align: center; }
  .rules .properties-summary {
    background-color: #e3e3e3;
    padding: 15px 15px 5px; }
  .rules .rule-attribute {
    border-bottom: 1px dotted #4c4c4c;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 500; }
  .rules .edit-rule {
    cursor: pointer; }
  .rules .offset-controls {
    top: 3px; }
    .rules .offset-controls li {
      height: 34px;
      max-height: 34px; }
      .rules .offset-controls li .fa {
        line-height: 34px; }
    .rules .offset-controls.rules-controls {
      cursor: normal;
      color: #bbbbbb; }

form .form-well {
  line-height: 32px;
  text-align: center; }
  form .form-well div {
    background-color: #e3e3e3;
    color: #848484;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.06em;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    margin: 0 -15px; }
    @media (max-width: 991px) {
      form .form-well div {
        margin: 10px auto;
        width: 20%; } }

form .input-group.buttonless .form-control:first-child {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }
form .input-group.buttonless .form-control:not(:last-child) {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0; }
form .input-group.buttonless .input-group-btn {
  text-align: right; }
  form .input-group.buttonless .input-group-btn .btn {
    padding-left: 0;
    padding-right: 0;
    margin-left: 7px; }

form.form-horizontal.rule-form .form-group {
  margin-left: 0;
  margin-right: 0; }

.rule-domains .panel {
  margin-bottom: 10px; }
.rule-domains .checkbox {
  margin: 0;
  padding-left: 0;
  padding-top: 0; }
  .rule-domains .checkbox label {
    display: block;
    padding: 7px 12px 7px 32px; }

form .property-filter {
  margin-bottom: 5px; }
  form .property-filter + .form-well {
    position: absolute; }
    form .property-filter + .form-well div {
      margin: 0;
      width: 42px; }
    form .property-filter + .form-well + .property-filter .col-sm-4:first-child {
      padding-left: 66px; }
  form .property-filter .remove-rule-property {
    color: #973f02;
    position: absolute;
    top: 0;
    right: 0; }
    form .property-filter .remove-rule-property:hover {
      color: #f97416; }
  form .property-filter .input-group.buttonless .remove-rule-property {
    position: relative; }
  form .property-filter .input-group.buttonless *:nth-last-child(0n+2) {
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    form .property-filter .input-group.buttonless *:nth-last-child(0n+2).rule-property-value-label {
      border-left: 0; }

.path-property .selectize-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }

.fa-check.saving-icons {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s; }

.autocomplete-suggestions {
  border: 1px solid #999999;
  background: white;
  overflow: auto; }
  .autocomplete-suggestions .autocomplete-suggestion {
    padding: 3px 12px;
    overflow: hidden; }
    .autocomplete-suggestions .autocomplete-suggestion:hover {
      cursor: pointer; }
  .autocomplete-suggestions .autocomplete-selected {
    background: #428bca;
    color: white; }
  .autocomplete-suggestions .autocomplete-suggestions strong {
    font-weight: normal;
    color: #3399ff; }
  .autocomplete-suggestions .autocomplete-group {
    padding: 2px 5px; }
  .autocomplete-suggestions .autocomplete-group strong {
    display: block;
    border-bottom: 1px solid black; }
