.step-action-form .control-link, .component-preview .control-link, .step-action-form .field-action, .component-preview .field-action {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .step-action-form .control-link:hover, .component-preview .control-link:hover, .step-action-form .field-action:hover, .component-preview .field-action:hover {
    color: #1e1e1e; }

.step-action-form .control-link, .component-preview .control-link, .step-action-form .field-action, .component-preview .field-action {
  color: #e3e3e3;
  cursor: pointer;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  transition-property: color; }
  .step-action-form .control-link:hover, .component-preview .control-link:hover, .step-action-form .field-action:hover, .component-preview .field-action:hover {
    color: #1e1e1e; }

.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  visibility: visible !important;
  -webkit-box-shadow: inset 0 0 12px 4px white;
  box-shadow: inset 0 0 12px 4px white; }
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: "!";
  visibility: hidden; }
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }

.selectize-dropdown-header {
  position: relative;
  padding: 3px 12px;
  background: #f8f8f8;
  border-bottom: 1px solid #d0d0d0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0; }

.selectize-dropdown-header-close {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -12px;
  font-size: 20px !important;
  line-height: 20px;
  color: #1e1e1e;
  opacity: 0.4; }
  .selectize-dropdown-header-close:hover {
    color: #1e1e1e; }

.selectize-dropdown.plugin-optgroup_columns .optgroup {
  float: left;
  border-top: 0 none;
  border-right: 1px solid #f2f2f2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
    border-right: 0 none; }
  .selectize-dropdown.plugin-optgroup_columns .optgroup:before {
    display: none; }
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none; }

.selectize-control {
  position: relative; }
  .selectize-control.plugin-remove_button [data-value] {
    position: relative;
    padding-right: 24px !important; }
    .selectize-control.plugin-remove_button [data-value] .remove {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 17px;
      padding: 1px 0 0 0;
      font-size: 12px;
      font-weight: bold;
      color: inherit;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      border-left: 1px solid rgba(0, 0, 0, 0);
      -webkit-border-radius: 0 2px 2px 0;
      -moz-border-radius: 0 2px 2px 0;
      border-radius: 0 2px 2px 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .selectize-control.plugin-remove_button [data-value] .remove:hover {
        background: rgba(0, 0, 0, 0.05); }
    .selectize-control.plugin-remove_button [data-value].active .remove {
      border-left-color: rgba(0, 0, 0, 0); }
  .selectize-control.plugin-remove_button .disabled [data-value] .remove {
    border-left-color: rgba(77, 77, 77, 0); }
    .selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
      background: none; }

.selectize-dropdown {
  font-size: 16px;
  -webkit-font-smoothing: inherit;
  line-height: 20px;
  color: #1e1e1e; }

.selectize-input {
  font-size: 16px;
  -webkit-font-smoothing: inherit;
  color: #4c4c4c;
  display: inline-block;
  cursor: text;
  background: white; }
  .selectize-input input {
    font-size: 16px;
    -webkit-font-smoothing: inherit;
    line-height: 20px;
    color: #1e1e1e; }

.selectize-control.single .selectize-input.input-active {
  display: inline-block;
  cursor: text;
  background: white; }

.selectize-input {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  padding: 6px 12px;
  overflow: hidden;
  border: 2px solid #dce4ec;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.selectize-control.multi .selectize-input.has-items {
  padding: 5px 12px 2px; }

.selectize-input.full {
  background-color: white; }
.selectize-input.disabled {
  cursor: default !important; }
  .selectize-input.disabled * {
    cursor: default !important; }
.selectize-input.dropdown-active {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0; }
.selectize-input > * {
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  vertical-align: baseline;
  zoom: 1; }

.selectize-control.multi .selectize-input > div {
  padding: 1px 3px;
  margin: 0 3px 3px 0;
  color: #1e1e1e;
  cursor: pointer;
  background: #efefef;
  border: 0 solid rgba(0, 0, 0, 0); }
  .selectize-control.multi .selectize-input > div.active {
    color: white;
    background: #428bca;
    border: 0 solid rgba(0, 0, 0, 0); }
.selectize-control.multi .selectize-input.disabled > div {
  color: gray;
  background: white;
  border: 0 solid rgba(77, 77, 77, 0); }
  .selectize-control.multi .selectize-input.disabled > div.active {
    color: gray;
    background: white;
    border: 0 solid rgba(77, 77, 77, 0); }

.selectize-input > input {
  max-width: 100% !important;
  max-height: none !important;
  min-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: inherit !important;
  text-indent: 0 !important;
  background: none !important;
  border: 0 none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-user-select: auto !important; }
  .selectize-input > input:focus {
    outline: none !important; }
.selectize-input::after {
  display: block;
  clear: left;
  content: " "; }
.selectize-input.dropdown-active::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  height: 1px;
  background: white;
  content: " "; }

.selectize-dropdown {
  position: absolute;
  z-index: 10;
  margin: -1px 0 0 0;
  background: white;
  border: 2px solid #dce4ec;
  border-top: 0 none;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .selectize-dropdown [data-selectable] {
    overflow: hidden;
    cursor: pointer;
    padding: 3px 12px; }
    .selectize-dropdown [data-selectable] .highlight {
      background: rgba(255, 237, 40, 0.4);
      -webkit-border-radius: 1px;
      -moz-border-radius: 1px;
      border-radius: 1px; }
  .selectize-dropdown .optgroup-header {
    padding: 3px 12px; }
  .selectize-dropdown .optgroup:first-child .optgroup-header {
    border-top: 0 none; }
  .selectize-dropdown .optgroup-header {
    color: #999999;
    cursor: default;
    background: white; }
  .selectize-dropdown .active {
    color: white;
    background-color: #428bca; }
    .selectize-dropdown .active.create {
      color: white; }
  .selectize-dropdown .create {
    color: rgba(51, 51, 51, 0.5); }

.selectize-dropdown-content {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto; }

.selectize-control.single .selectize-input {
  cursor: pointer;
  height: 34px; }
  .selectize-control.single .selectize-input input {
    cursor: pointer; }
  .selectize-control.single .selectize-input.input-active {
    cursor: text; }
    .selectize-control.single .selectize-input.input-active input {
      cursor: text; }
  .selectize-control.single .selectize-input:after {
    position: absolute;
    top: 52%;
    right: 10px;
    display: block;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    content: " "; }
  .selectize-control.single .selectize-input.dropdown-active:after {
    margin-top: -4px;
    border-color: transparent transparent transparent transparent;
    border-width: 0 5px 5px 5px; }
.selectize-control.rtl.single .selectize-input:after {
  right: auto;
  left: 17px; }
.selectize-control.rtl .selectize-input > input {
  margin: 0 4px 0 -2px !important; }
.selectize-control .selectize-input.disabled {
  background-color: white;
  opacity: 0.5; }

.selectize-dropdown, .autocomplete-suggestions {
  z-index: 1000;
  height: auto;
  padding: 0;
  margin: 2px 0 0 0;
  background: white;
  border: 2px solid #dce4ec;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .selectize-dropdown.form-control, .autocomplete-suggestions.form-control {
    z-index: 1000;
    height: auto;
    padding: 0;
    margin: 2px 0 0 0;
    background: white;
    border: 2px solid #dce4ec;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .selectize-dropdown .optgroup-header, .autocomplete-suggestions .optgroup-header {
    font-size: 12px;
    line-height: 1.42857; }
  .selectize-dropdown .optgroup:first-child:before, .autocomplete-suggestions .optgroup:first-child:before {
    display: none; }
  .selectize-dropdown .optgroup:before, .autocomplete-suggestions .optgroup:before {
    display: block;
    height: 1px;
    margin: 9px 0;
    margin-right: -12px;
    margin-left: -12px;
    overflow: hidden;
    background-color: #e5e5e5;
    content: " "; }

.selectize-dropdown-content {
  padding: 5px 0; }

.selectize-dropdown-header {
  padding: 6px 12px; }

.selectize-input {
  height: 34px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.15s ease;
  -moz-transition: border-color 0.15s ease;
  -o-transition: border-color 0.15s ease;
  transition: border-color 0.15s ease; }
  .selectize-input.dropdown-active {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
    .selectize-input.dropdown-active::before {
      display: none; }
  .selectize-input.focus {
    border-color: #5c5cff;
    outline: 0; }

.selectize-control.multi .selectize-input.has-items {
  padding-right: 9px;
  padding-left: 9px; }
.selectize-control.multi .selectize-input > div {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.form-control.selectize-control {
  height: auto;
  padding: 0;
  background: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.selectize-control .label, .selectize-control .caption {
  display: block;
  color: #4c4c4c;
  text-align: left; }
.selectize-control .label {
  padding: 0;
  border-radius: 0;
  line-height: 1;
  font-weight: normal;
  font-size: 16px; }
.selectize-control .caption {
  font-size: 12px;
  color: #848484; }
.selectize-control .selectize-input .label, .selectize-control .selectize-input .caption {
  display: inline-block; }
.selectize-control .selectize-input .label {
  position: absolute;
  right: 43px;
  top: 4px;
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-color: #e3e3e3;
  max-width: 110px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: inline-block; }
.selectize-control .selectize-input .caption {
  font-size: 13px;
  max-width: 250px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: inline-block; }
.selectize-control .selectize-dropdown-content div:hover .label, .selectize-control .selectize-dropdown-content div:hover .caption, .selectize-control .selectize-dropdown-content div.active .label, .selectize-control .selectize-dropdown-content div.active .caption {
  color: white; }

.step-action-form .form-field, .component-preview .form-field {
  background-color: white;
  margin: 0 0 10px; }
.step-action-form .field-type-buttons, .component-preview .field-type-buttons {
  display: table;
  width: 100%; }
.step-action-form .field-type-btn, .component-preview .field-type-btn {
  display: table-cell;
  padding: 0px 5px;
  text-align: center; }
.step-action-form .field-label, .component-preview .field-label {
  display: table;
  width: 100%; }
.step-action-form .field-actions, .component-preview .field-actions {
  display: table-cell;
  width: 66px; }
.step-action-form .field-action, .component-preview .field-action {
  margin: 0 2px;
  padding: 0 3px; }
  .step-action-form .field-action:focus, .component-preview .field-action:focus {
    outline: none; }
.step-action-form .field-drag-handle, .component-preview .field-drag-handle {
  cursor: move; }
.step-action-form .field-actions .btn.active, .component-preview .field-actions .btn.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }
.step-action-form input.label-value, .component-preview input.label-value {
  border: none;
  display: table-cell;
  font-weight: bold;
  margin: 5px 0;
  outline: none;
  width: 100%; }
.step-action-form label.label-display, .component-preview label.label-display {
  border: none;
  display: table-cell;
  font-weight: bold;
  margin: 5px 0;
  outline: none;
  width: 100%; }
.step-action-form input.placeholder-value, .component-preview input.placeholder-value {
  border: 1px solid #dddddd;
  color: #aaaaaa;
  padding: 6px 10px;
  width: 100%; }
.step-action-form input.placeholder-display, .component-preview input.placeholder-display {
  border: 1px solid #dddddd;
  padding: 6px 10px;
  width: 100%; }
.step-action-form textarea.placeholder-value, .component-preview textarea.placeholder-value {
  color: #aaaaaa;
  border: 1px solid #dddddd;
  font-size: 14px;
  padding: 6px 10px;
  width: 100%; }
.step-action-form textarea.placeholder-display, .component-preview textarea.placeholder-display {
  border: 1px solid #dddddd;
  font-size: 14px;
  padding: 6px 10px;
  width: 100%; }
.step-action-form .rating-options, .component-preview .rating-options {
  display: table;
  width: 100%; }
.step-action-form .rating-option, .component-preview .rating-option {
  cursor: pointer;
  display: table-cell;
  font-weight: normal;
  text-align: center;
  width: 9%; }
  .step-action-form .rating-option span, .component-preview .rating-option span {
    background-color: white;
    border: 1px solid white;
    display: block;
    padding: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
    .step-action-form .rating-option span:hover, .component-preview .rating-option span:hover {
      background-color: #888888;
      border: 1px solid #777777;
      color: white; }
  .step-action-form .rating-option input, .component-preview .rating-option input {
    display: none; }
.step-action-form label.field-option, .component-preview label.field-option {
  background-color: white;
  display: block;
  font-weight: normal;
  margin: 5px 0;
  padding: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px; }
  .step-action-form label.field-option input[type="radio"], .component-preview label.field-option input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .step-action-form label.field-option input[type="radio"] + span::before, .component-preview label.field-option input[type="radio"] + span::before {
    content: "";
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    vertical-align: -0.05em;
    border: 0.125em solid white;
    margin-right: 0.7em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
    border-radius: 1em;
    -webkit-box-shadow: 0 0 0 0.15em #888888;
    -moz-box-shadow: 0 0 0 0.15em #888888;
    box-shadow: 0 0 0 0.15em #888888;
    -webkit-transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all; }
  .step-action-form label.field-option input[type="radio"]:checked + span::before, .component-preview label.field-option input[type="radio"]:checked + span::before {
    background: #5cb85c;
    -webkit-box-shadow: 0 0 0 0.25em #888888;
    -moz-box-shadow: 0 0 0 0.25em #888888;
    box-shadow: 0 0 0 0.25em #888888; }
  .step-action-form label.field-option input.option-value, .component-preview label.field-option input.option-value {
    border: none;
    background-color: inherit;
    display: inline-block;
    width: 95%; }
    .step-action-form label.field-option input.option-value:focus, .component-preview label.field-option input.option-value:focus {
      outline: none; }
  .step-action-form label.field-option .option-drag-handle, .component-preview label.field-option .option-drag-handle {
    cursor: move;
    margin-right: 5px;
    opacity: 0.4; }
.step-action-form .add-field-option, .component-preview .add-field-option {
  color: #e3e3e3;
  cursor: pointer;
  display: block;
  font-size: small;
  margin-top: 10px;
  margin-left: 8px;
  text-decoration: none; }
.step-action-form .add-field, .component-preview .add-field {
  clear: both;
  margin-top: 10px;
  text-decoration: none; }

appcues[data-pattern-type='shorty'] .component-editor {
  padding: 0; }
  appcues[data-pattern-type='shorty'] .component-editor .build-form-fields {
    overflow-y: auto;
    height: 300px;
    padding: 0 10px 10px 10px; }
    appcues[data-pattern-type='shorty'] .component-editor .build-form-fields .field-type-buttons .field-type-btn {
      display: block;
      float: left;
      width: 20%;
      padding: 0; }
      appcues[data-pattern-type='shorty'] .component-editor .build-form-fields .field-type-buttons .field-type-btn .choose-field-type span {
        display: none; }

.select-field-type .selectize-control {
  float: left;
  width: calc(100% - 30px); }
  .select-field-type .selectize-control .icon {
    float: left;
    margin-right: 10px; }
  .select-field-type .selectize-control .text {
    float: left; }
.select-field-type .remove {
  width: 20px;
  float: left; }
