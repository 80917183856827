.hover-controls {
  background-color: rgba(30, 30, 30, 0.9);
  display: none;
  flex-direction: row;
  font-size: small;
  font-weight: 400;
  padding: 3px 6px;
  position: absolute;
  text-align: center;
  bottom: 100%;
  left: -1px;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0; }
  .hover-controls a {
    color: #bbbbbb;
    cursor: pointer;
    display: flex;
    padding: 4px 6px;
    margin-right: 5px;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    -o-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    white-space: nowrap; }
    .hover-controls a:last-child {
      margin-right: 0; }
    .hover-controls a:hover {
      color: white;
      text-decoration: none; }
    .hover-controls a.component-drag-handle {
      cursor: move; }
    .hover-controls a i {
      line-height: 23px; }
