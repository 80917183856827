.steps {
  height: 100%; }
  .published.steps .preview-area {
    margin-top: 0;
    padding-top: 0; }
    .published.steps .preview-area .frame-wrapper, .published.steps .preview-area .mask {
      top: 0; }
  .steps .preview-area {
    min-height: 100%;
    position: relative;
    z-index: 8; }
    .steps .preview-area .frame-wrapper, .steps .preview-area .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .steps .preview-area .frame-wrapper iframe {
      background-color: white;
      border: none;
      width: 100%;
      height: 100%;
      display: block; }
    .steps .preview-area .mask {
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-transition: background-color 0.2s ease-out;
      -moz-transition: background-color 0.2s ease-out;
      -o-transition: background-color 0.2s ease-out;
      transition: background-color 0.2s ease-out;
      -webkit-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 1px 5px 10px -6px rgba(0, 0, 0, 0.2); }
  .fullscreen-pattern.steps .preview-area .mask {
    background-color: #eeeeee; }

.steps .preview-area.has-navigate .mask, .steps .preview-area.has-navigate .flow-steps {
  display: none; }
.steps.published .toolbar {
  display: none; }
.steps .flow-steps {
  margin: 0 auto;
  max-width: 650px;
  min-width: 400px;
  padding: 60px 0; }
.steps .close {
  position: absolute;
  top: 5px;
  right: 5px; }
.steps.left-pattern .flow-steps {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0; }
  @media (min-width: 992px) {
    .steps.left-pattern .flow-steps {
      width: 650px; } }
.steps.left-pattern .step {
  height: 100%;
  overflow-y: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0; }
.steps.left-pattern .step-action {
  min-height: 100%; }
