.content-analytics table.dynatable thead th {
  background-color: #e3e3e3;
  font-weight: inherit; }
  .content-analytics table.dynatable thead th a {
    color: #4c4c4c; }

.flow-analytics .step-column {
  display: inline-block; }
.flow-analytics .step-users {
  color: #999999;
  font-size: small; }
.flow-analytics .step-wrapper {
  border-bottom: 1px solid #cccccc;
  height: 100px;
  margin: 5px 0;
  position: relative; }
  .flow-analytics .step-wrapper .step-bar {
    background-color: #3498db;
    position: absolute;
    bottom: 0px;
    left: 3px;
    right: 3px; }
    .flow-analytics .step-wrapper .step-bar.goal-bar {
      background-color: #06db82; }
.flow-analytics .step-duration, .flow-analytics .step-goal {
  color: #999999;
  font-size: small; }

.flow-users table.users {
  border: 0; }
  .flow-users table.users th {
    font-weight: normal;
    color: #e3e3e3;
    font-size: small;
    border: 0;
    padding-top: 5px;
    padding-bottom: 5px; }
    .flow-users table.users th a {
      color: inherit; }
  .flow-users table.users tr.user {
    cursor: pointer; }
  .flow-users table.users tr.details {
    display: none;
    font-size: small; }
    .flow-users table.users tr.details > td {
      border-top: 0; }
    .flow-users table.users tr.details .user-properties table td, .flow-users table.users tr.details .form-responses table td {
      background-color: #f9f9f9; }
    .flow-users table.users tr.details h5 {
      font-size: 15px;
      margin: 5px 0 10px; }
.flow-users .panel-footer {
  font-size: small; }
