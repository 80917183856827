.integration-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  margin-right: -2%; }

.integration-panel {
  flex-basis: 23%;
  margin-right: 2%;
  min-height: 110px; }
  .integration-panel.enabled {
    border: 1px solid #23c74e;
    box-shadow: inset 0 0 0 2px #23c74e; }

.integration-panel-body {
  height: 100%;
  position: relative;
  padding: 0 15px; }

.integration .integration-logo img {
  padding: 0 5px; }
.integration .btn.active {
  cursor: default; }
.integration a.cancel-configuration {
  position: absolute;
  right: 0;
  top: 0; }

.integration-panel-body {
  padding: 10px !important; }
  .integration-panel-body .integration-logo {
    transition: opacity 0.3s ease; }
  .integration-panel-body .integration-action {
    position: absolute;
    left: 20px;
    right: 20px;
    width: auto;
    top: 50%;
    margin-top: -16px;
    transition: opacity 0.3s ease;
    opacity: 0; }
  .integration-panel-body:hover .integration-logo, .integration-panel-body .integration.pending .integration-logo, .integration-panel-body .integration.configuring .integration-logo {
    opacity: 0.1; }
  .integration-panel-body:hover .integration-action, .integration-panel-body .integration.pending .integration-action, .integration-panel-body .integration.configuring .integration-action {
    opacity: 1; }
  .integration-panel-body .integration.configuring {
    padding-bottom: 54px; }
    .integration-panel-body .integration.configuring .integration-action {
      top: 30px; }
  .integration-panel-body p.text-success {
    font-size: 14px; }
