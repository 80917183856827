.step {
  background-color: white;
  color: #666666;
  margin: 0;
  padding: 1rem;
  position: relative;
  width: 100%; }
  .step cue {
    display: block; }
  .step ul.redactor_toolbar {
    background-color: #e5e5e5;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: none;
    color: #7d8a94;
    text-align: center; }
  .step header {
    margin-bottom: 2rem; }
    .step header h2 {
      text-align: center;
      font-weight: 200;
      margin: 1rem 0;
      font-size: 18px;
      color: #222222; }
  .step .well {
    cursor: pointer;
    -webkit-transition: color 0.2s ease-out, background-color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out, background-color 0.2s ease-out;
    -o-transition: color 0.2s ease-out, background-color 0.2s ease-out;
    transition: color 0.2s ease-out, background-color 0.2s ease-out; }
    .step .well:hover {
      color: #222222;
      background-color: #ececec; }
  .step + .step {
    margin-top: 2rem; }
    .step + .step:before {
      content: "";
      position: absolute;
      top: -2rem;
      width: 0;
      left: 50%;
      height: 2rem;
      border-right: 2px dashed #cccccc; }

.appcues-responsive-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  display: block; }
  .appcues-responsive-embed iframe, .appcues-responsive-embed object, .appcues-responsive-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
