.settings-panel {
  background-color: #e8e8e8;
  border: 1px solid gainsboro;
  border-radius: 5px;
  margin: 15px 0;
  padding: 15px; }
  .settings-panel h4 {
    font-size: 16px;
    margin: 0 0 15px; }

.content-thumbnails {
  margin: 1rem 0 0; }
  .content-thumbnails .thumbnails-list {
    margin-left: 0;
    margin-bottom: 0; }
    .content-thumbnails .thumbnails-list .thumbnail-item.dashed.add-step:hover {
      color: inherit;
      border-color: #888888;
      opacity: 1;
      cursor: pointer; }
