.install-container h3, .install-guide-section h3, .invite-dev-container h3 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px; }
.install-container h4, .install-guide-section h4, .invite-dev-container h4 {
  font-weight: 600; }

.guide {
  margin-bottom: 20px; }
  .guide .invite-dev-container .invite-section {
    margin: 40px 0; }
    .guide .invite-dev-container .invite-section #invite {
      display: flex;
      width: 100%;
      margin-bottom: 10px; }
      .guide .invite-dev-container .invite-section #invite .form-group {
        margin-right: 8px;
        flex-grow: 1; }
        .guide .invite-dev-container .invite-section #invite .form-group.email-group {
          flex-grow: 1.5; }
        .guide .invite-dev-container .invite-section #invite .form-group label {
          display: block;
          font-weight: 600;
          color: #848484; }
        .guide .invite-dev-container .invite-section #invite .form-group input {
          width: 100%; }
    .guide .invite-dev-container .invite-section .open-mailto {
      min-width: 213px; }
    .guide .invite-dev-container .invite-section .email-body {
      padding: 10px;
      margin-bottom: 10px; }
    .guide .invite-dev-container .invite-section p.invite-desc {
      color: #7b8a8b;
      font-size: 15px; }
    .guide .invite-dev-container .invite-section p.preview {
      display: inline;
      cursor: pointer;
      color: #35aae6; }
  .guide .invite-dev-container .share {
    margin-left: 5px;
    padding: 5px 10px;
    background-color: #f5f2f0;
    text-align: center;
    cursor: pointer; }
    .guide .invite-dev-container .share input {
      width: 305px;
      margin-left: 5px;
      border: none;
      background-color: #f5f2f0; }
  .guide .invite-dev-container .fa-check {
    margin: 3px 5px;
    opacity: 0; }
  .guide .invite-dev-container .send-instructions {
    display: flex;
    padding-bottom: 20px; }
    .guide .invite-dev-container .send-instructions .send-instructions-btn {
      width: 175px; }
  .guide .invite-dev-container .preview-backdrop {
    min-height: 400px;
    background-color: #f5f2f0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 30px 0; }
    .guide .invite-dev-container .preview-backdrop .preview-controls {
      position: absolute;
      top: 15px;
      right: 20px;
      font-size: 20px;
      cursor: pointer; }
      .guide .invite-dev-container .preview-backdrop .preview-controls .fa {
        margin-left: 5px; }
    .guide .invite-dev-container .preview-backdrop .preview-email {
      min-height: 300px;
      width: 80%;
      background-color: white;
      border: 1px solid #f5f2f0;
      border-radius: 3px;
      padding: 20px; }
      .guide .invite-dev-container .preview-backdrop .preview-email .preview-close {
        position: absolute;
        bottom: 20px;
        right: 20px; }
  .guide .invite-dev-container form#email {
    margin: 10px 0; }
  .guide .invite-dev-container .invite-section-cta {
    margin: 25px;
    padding: 12px 25px 25px;
    background-color: #f5f2f0; }
    .guide .invite-dev-container .invite-section-cta h4 {
      font-weight: 600; }
  .guide .invite-dev-container .alert-block {
    display: inline-block;
    width: 75%;
    margin-top: 10px;
    font-size: 14px;
    vertical-align: middle; }
    .guide .invite-dev-container .alert-block .error-message {
      color: red;
      font-style: italic; }
    .guide .invite-dev-container .alert-block .success-message {
      color: green;
      font-style: italic; }
  .guide .install-guide .install-guide-section {
    margin: 45px 0; }
    .guide .install-guide .install-guide-section h4.panel-title {
      margin-bottom: 10px; }
    .guide .install-guide .install-guide-section p {
      color: #7b8a8b;
      line-height: 1.5em;
      margin-bottom: 1.25em;
      max-width: 700px; }
    .guide .install-guide .install-guide-section > pre {
      margin-bottom: 1.25rem; }
      .guide .install-guide .install-guide-section > pre > code {
        padding: 1rem;
        overflow: auto !important;
        font-family: "source-code-pro", Menlo, Monaco, Consolas, "Courier New", monospace;
        color: #4e4d4c; }
    .guide .install-guide .install-guide-section code {
      margin: 0 0.2em 0 0.3em; }
  .guide .install-guide .installation-options {
    display: flex;
    justify-content: space-between; }
    .guide .install-guide .installation-options .install-options-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-right: -10px; }
      .guide .install-guide .installation-options .install-options-container .install-option-button {
        display: flex;
        align-items: center;
        width: 47%;
        padding: 15px 10px 15px 25px;
        margin: 0 15px 15px 0;
        border: 1px solid lightgrey;
        border-radius: 4px;
        color: #7b8a8b;
        cursor: pointer; }
        .guide .install-guide .installation-options .install-options-container .install-option-button.selected-install {
          border-color: #2ca6e5;
          background-color: #2ca6e5;
          color: white; }
        .guide .install-guide .installation-options .install-options-container .install-option-button .install-icon {
          display: grid;
          font-size: 26px;
          margin-right: 10px;
          width: 25px;
          height: 25px; }
