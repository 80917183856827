.appcues-window-alert.sweet-alert {
  font-family: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 16px; }

body.stop-scrolling {
  overflow: inherit; }

.appcues-window-alert.sweet-alert button {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px; }
  .appcues-window-alert.sweet-alert button.confirm {
    background-color: #5c5cff; }
    .appcues-window-alert.sweet-alert button.confirm:hover {
      background-color: #2929ff;
      border-color: #1f1fff; }
  .appcues-window-alert.sweet-alert button.cancel {
    background-color: #848484; }
    .appcues-window-alert.sweet-alert button.cancel:hover {
      background-color: #6b6b6b;
      border-color: #656565; }

.notice {
  display: block;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-top: 1.5rem; }
  .notice p {
    margin-bottom: 0;
    padding: 0px 35px 0 0;
    font-size: 16px;
    width: 100%; }
  .notice .help {
    color: #8d7102 !important;
    text-decoration: underline; }
    .notice .help:hover, .notice .help:focus, .notice .help:active {
      color: #8d7102 !important; }
  .notice .btn + .btn {
    margin-left: 0.75rem; }
