.hilighter {
  padding: 4px 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-color: #e3e3e3;
  color: #1e1e1e; }
  .hilighter.hilighter-success {
    background-color: #defaee;
    color: #09bb71; }
  .hilighter.hilighter-info {
    background-color: #def7ff;
    color: #006d8c; }
  .hilighter.hilighter-warning {
    background-color: #fef6d9;
    color: #8d7102; }
  .hilighter.hilighter-danger {
    background-color: #fff1e8;
    color: #f97416; }
