.checklist table {
  font-size: 16px; }
  .checklist table td.icon {
    text-align: center;
    width: 40px; }
  .checklist table td.description .property-keys {
    font-size: small;
    margin: 10px 0 0; }
    .checklist table td.description .property-keys ul li {
      display: inline-block; }
      .checklist table td.description .property-keys ul li span {
        background-color: #eeeeee;
        padding: 2px 8px;
        margin: 3px;
        border-radius: 2px; }

.installation-status td.image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  width: 80px; }
.installation-status td.user-details {
  padding: 15px 10px !important;
  font-size: 16px; }
.installation-status td.main {
  padding: 12px 10px 10px !important; }
  .installation-status td.main h5 {
    font-size: 16px;
    margin: 0; }
    .installation-status td.main h5 a {
      color: inherit; }
.installation-status td.stats {
  padding-top: 18px !important;
  text-align: center;
  width: 50px; }
.installation-status td.indicators {
  padding: 18px 0 !important;
  width: 80px; }
.installation-status td.published-flag {
  padding: 18px 10px !important;
  text-align: right;
  width: 70px; }
