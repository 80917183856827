.flow-summary {
  color: #4a5353;
  font-size: 16px;
  line-height: 1.5em;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out; }
  .opened.flow-summary {
    color: rgba(74, 83, 83, 0.5); }

.flow-summary .flow-setting {
  color: #4a5353;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  position: relative; }
  .flow-summary .open-panel.flow-setting {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: pointer; }
  .flow-summary .with-error.flow-setting {
    position: relative; }
    .flow-summary .with-error.flow-setting:after {
      top: 100%;
      margin-top: -5px;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(179, 78, 77, 0);
      border-bottom-color: #b34e4d;
      border-width: 10px;
      margin-left: -10px; }

.flow-summary.opened .flow-setting {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  color: rgba(74, 83, 83, 0.5);
  -webkit-transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out; }
  .flow-summary.opened .flow-setting:hover, .flow-summary.opened .active.flow-setting {
    border-bottom-color: rgba(0, 0, 0, 0.7);
    color: #4a5353; }

.pattern-choice, .skippable-choice, .frequency-choice {
  padding: 20px;
  text-align: left; }
  .pattern-choice .choice-label, .skippable-choice .choice-label, .frequency-choice .choice-label {
    font-size: 16px;
    height: 34px;
    line-height: 34px; }
  .pattern-choice .icon, .skippable-choice .icon, .frequency-choice .icon {
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    height: 35px;
    margin-right: 15px;
    position: relative;
    width: 55px; }
    .pattern-choice .icon .fill, .skippable-choice .icon .fill, .frequency-choice .icon .fill {
      background-color: white;
      position: absolute; }

.pattern-choice .icon.pattern-icon-left .fill, .skippable-choice .icon.pattern-icon-left .fill, .frequency-choice .icon.pattern-icon-left .fill {
  bottom: 0;
  left: 0;
  right: 65%;
  top: 0; }
.pattern-choice .icon.pattern-icon-modal .fill, .skippable-choice .icon.pattern-icon-modal .fill, .frequency-choice .icon.pattern-icon-modal .fill {
  border-radius: 2px;
  bottom: 20%;
  left: 20%;
  right: 20%;
  top: 20%; }
.pattern-choice .icon.pattern-icon-full .fill, .skippable-choice .icon.pattern-icon-full .fill, .frequency-choice .icon.pattern-icon-full .fill {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }
